import React from 'react';
import DialPad, * as DialPadImpl from './components/DialPad';
//import Empty from './components/Empty';
//import util from 'util';
import * as LIB from './lib/lib.js';
import AppSetup from './app_setup.js';
import autobind from 'autobind-decorator';
import I18n from './lib/i18n.js';
import {
	Text,
	View,
	//TouchableWithoutFeedback,
	StyleSheet,
} from 'react-native-web';

import { 
	Button as ButtonElement,
} from 'react-native-elements';

import MaterialIcon from 'react-native-vector-icons/dist/MaterialIcons';

//import debounce from 'lodash/debounce';
import { MaterialTheme } from './lib/styles.js';
const Theme = MaterialTheme[AppSetup.Theme];
const xlog = require('./lib/xlog.js'); // --- use reqiure so that we can use `pure_funcs` to strip in production build

@autobind
class Talk extends React.Component {

	constructor(props) {
		super(props);
		//props.redux = {}; // --- workaround

		this.talkingCallData = null;
		this.isStart = true;
		this.isStopping = false;
		this.isTimerStarted = false;

		// --- use debounce may results in 'setState on unmounted components'
		//this.onPressMicMute = debounce(this.onPressMicMute, 200);
		//this.onPressChangeAudioRoute = debounce(this.onPressChangeAudioRoute, 200);
		//this.onPressCameraMute = debounce(this.onPressCameraMute, 200);
		//this.onPressSwitchVideo = debounce(this.onPressSwitchVideo, 200);
		//this.onPressOpenDoor = debounce(this.onPressOpenDoor, 200);
		//
		this.state = {
			dtmfs: '',
			isKeyboardOpen: false,
			view: 'talking',
			isToggleAudioDeviceOn: true, // --- default true
			isMicMute: false,
			isCameraMute: false,
			isHangUpBtnPressed: false,
			largeVideoRenderTypePreferLocal: false,
			shouldShowControlView: true,
			callDuration: 0,
			localCameraFacingModeEnvironment: false, // --- use use facing mode = "user" by default
		};

		this.resetState({isConstructor: true});
		xlog.info('Talk.constructor');
	}

	componentDidCatch(error, info) {
		xlog.info('[Talk] componentDidCatch, error = ', error);
		xlog.info('[Talk] componentDidCatch, info = ', info);
	}

	componentDidMount() {
		xlog.info('[Talk] componentDidMount');
	}

	componentWillUnmount() {
		xlog.info('[Talk] componentWillUnmount');
	}

	resetState(info) {
		if (!info) {
			xlog.info('[Talk] resetState() but info is null');
			info = {};
		}

		let nextState = {
			dtmfs: '',
			isKeyboardOpen: false,
			view: info.view || 'talking',
			isToggleAudioDeviceOn: true,
			isMicMute: false,
			isCameraMute: false,
			isHangUpBtnPressed: info.stopCall || false,
			largeVideoRenderTypePreferLocal: false,
			shouldShowControlView: true,
			callDuration: 0,
			localCameraFacingModeEnvironment: false, // --- use use facing mode = "user" by default
		};

		this.callType = 'outgoing';
		this.isTimerStarted = false;

		if (info.isConstructor !== true) {
			this.setState(nextState);
		}
	}

	handleHangUp(calleeNumber) {
		xlog.info('Talk.handleHangUp: hangup button pressed');
		this.setState({isHangUpBtnPressed: true});
		this.props.parent.handleHangUp();
	}

	handleOnKeyboardSwitch() {
		xlog.info('keyboard button pressed ');
		this.setState({isKeyboardOpen: !this.state.isKeyboardOpen});
	}

	handleDialPadOnChangeText(text) {
		xlog.info('handleDialPadOnChangeText: text =', text);
		//DialPadImpl.handleOnChangeText.call(this, text, 'dtmfs');
		this.setState({dtmfs: text});
	}

	handleDialPadOnPress(button, isLongPress) {
		xlog.info('handleDialPadOnPress: button=', button, ', isLongPress=', isLongPress);
		let symbol = DialPadImpl.handleOnPress.call(this, button, isLongPress, this.state.dtmfs);
		this.props.parent.handleDTMF(symbol);
	}

	handleDialPadOnBackspace() {
		xlog.info('handleDialPadOnBackspace()');
		DialPadImpl.handleOnBackspace.call(this, this.state.dtmfs);
	}

	handleDialPadOnReset() {
		xlog.info('handleDialPadOnReset()');
		DialPadImpl.handleOnReset.call(this);
	}

	onPressMicMute() {
		let { localMediaStream } = this.props.parent.state;
		let isMicMute= !this.state.isMicMute;
		if (localMediaStream !== null) {
			let tracks = localMediaStream.getAudioTracks();
			if (tracks.length > 0) {
				tracks.forEach((element, index, array) => {
					element.enabled = !isMicMute; // --- this also mute
				})
				//InCallManager.setMicrophoneMute(isMicMute); // --- only android can shut the mic down programmatically.
				xlog.info("onPressMicMute() set isMicMute = ", isMicMute);
				this.setState({isMicMute: isMicMute});
			}
		} else {
			xlog.info("onPressMicMute() localstream is null");
		}
	}

	async onPressChangeAudioRoute() {
		let nextIsToggleAudioDeviceOn = !this.state.isToggleAudioDeviceOn;
		this.props.parent.remoteAudio.volume = (nextIsToggleAudioDeviceOn ? 1.0 : 0.0);
		this.setState({isToggleAudioDeviceOn: nextIsToggleAudioDeviceOn});
	}
	/*
	// --- 20190610: temp disable audio device selection, it may be confusing since AHOY APP is speakerphone toggle instead audio device toggle
	async changeAudioDeviceById(sinkId, lastTry=false) {
		let element = this.props.parent.remoteAudio;

		if (typeof element.sinkId !== 'undefined') {
			try {
				await element.setSinkId(sinkId)
			} catch (err) {
				let errorMessage = err;
				if (err.name === 'SecurityError') {
					errorMessage = `You need to use HTTPS for selecting audio output device: ${err}`;
				}
				xlog.info('changeAudioDeviceById() failed. %s', errorMessage);

				// --- Jump back to first output device in the list as it's the default.
				if (!lastTry) {
					let deviceInfos = await navigator.mediaDevices.enumerateDevices();
					if (deviceInfos.length > 0) {
						let deviceId = deviceInfos[0].deviceId;
						this.changeAudioDeviceById(deviceId, true);
					}
				}
			}
		} else {
			xlog.info('[Talk] changeAudioDeviceById() Browser does not support output device selection.');
		}
	}

	async onPressChangeAudioRoute() {
		let isSinkIdSupported = ('sinkId' in HTMLMediaElement.prototype);
		if (!isSinkIdSupported) {
			xlog.info('onPressChangeAudioRoute() sink id is not supported');
		}

		let audioOutputDevices = [];
		try {
			let deviceInfos = await navigator.mediaDevices.enumerateDevices();

			// Handles being called several times to update labels. Preserve values.
			for (let i = 0; i !== deviceInfos.length; ++i) {
				let deviceInfo = deviceInfos[i];
				let title = '';
				if (deviceInfo.kind === 'audiooutput') {
					title = deviceInfo.label || `speaker ${audioOutputDevices.length + 1}`;
					deviceInfo.title = title;
					audioOutputDevices.push(deviceInfo);
				}
			}
			if (audioOutputDevices.length > 0) {
				let modlaView = (
					<View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
						{audioOutputDevices.map((item, i) => (
							<View key={i}>
								<ButtonElement
									buttonStyle={[styles.mainButtonContainer, {width: this.props.expectedWidth * 0.9}]}
									title={item.title}
									titleStyle={{color: 'white', fontSize: 18}}
									onPress={() => this.changeAudioDeviceById(item.deviceId)}
								/>
								<Empty height={10} />
							</View>
						))}
					</View>
				);
				this.props.parent.setState({
					modal: {
						isModalOpen: true,
						view: modlaView,
					}
				});
			} else {
				xlog.info('[Talk] onPressChangeAudioRoute() no audioOutputDevices found');
			}
		} catch (err) {
			xlog.info('[Talk] onPressChangeAudioRoute() failed. ', err);
		}
	}
	*/

	startTalk(state) {
		this.isStart = true;
		this.talkingCallData = state;
		this.resetState(state);
	}

	stopTalk() {
		if (this.isStart !== true) return;
		this.isStopping = true;
		this.props.parent.destroyCallSession({busytone: false});
		//this.props.dispatch(Actions.UPDATE_TALKING_CALL_DATA(null));
		this.resetState({stopCall: true});
		this.talkingCallData = null;
		this.isStart = false;
		this.isStopping = false;
	}

	startCallTimer() {
		try {
			if (!this.isTimerStarted ||
					!this.props.parent.state.session_call_start_time ||
					!this.isStart ||
					this.isStopping) {
				return;
			}
		} catch (err) {
			xlog.info('[Talk][startCallTimer] exception. component might be unmounted. err = ', err);
			return;
		}
		//const nextDuration = this.state.callDuration + 1;
		const nextDuration = Math.round(Date.now()/1000) - this.props.parent.state.session_call_start_time;

		// --- workaround for ios
		if (nextDuration > 0 && this.props.parent.state.DetectRTC.osName === 'iOS') {
			if (nextDuration === 1 || nextDuration === 2) {
				// --- all ios should have workaround or it will play sound on internal speaker instead loudspeaker.
				this.props.parent.reactivateAudioForIosSafariWorkaround();
			} else {
				if (this.props.parent.state.webcallInfo.calleeNumber === '*599' || this.props.parent.state.webcallInfo.calleeNumber === '599599599') {
					// --- for 599 echo test, it should activate again when ready for test ( after IVR prompt )
					// --- since we can not detect when the prompt end, just do it at approximately 10 secs
					if (nextDuration === 10 || nextDuration === 12 || nextDuration === 14) {
						this.props.parent.reactivateAudioForIosSafariWorkaround();
					}
				}
			}
		}
		this.setState({callDuration: nextDuration}); // --- should trigger re-render
		setTimeout(this.startCallTimer, 1000); // --- check every one second
	}

	getMediaStreamURL(stream, getType) {
		if (!stream) {
			return null;
		}

		if (getType === 'video') {
			if (stream.getVideoTracks().length <= 0) {
				return null;
			}
		}

		if (getType === 'audio') {
			if (stream.getAudioTracks().length <= 0) {
				return null;
			}
		}

		return stream.toURL();
	}

	renderCallInfo() {
		const {
			calleeNumber,
			calleeName,
			calleeThumbnailPath,
		} = this.props.parent.state.webcallInfo;

		return (
			<CallInfo
				// --- global
				parent={this.props.parent}
				isHangUpBtnPressed={this.state.isHangUpBtnPressed}

				number={calleeNumber}
				callType={this.callType}
				calleeName={calleeName}
				viewType={this.state.view}
				callDuration={this.state.callDuration}
				call_session_event={this.props.parent.state.call_session_event}
				session_call_start_time={this.props.parent.state.session_call_start_time}
				thumbnailPath={calleeThumbnailPath}
			/>
		);
	}

	renderControlPanel() {
		return (
			<ControlPanel
				// --- global states
				parent={this.props.parent}
				callType={this.callType}
				viewType={this.state.view}
				call_session_event={this.props.parent.state.call_session_event}

				// --- ControlBttons onPress handler functions
				handleHangUp={this.handleHangUp}
				handleOnKeyboardSwitch={this.handleOnKeyboardSwitch}
				onPressMicMute={this.onPressMicMute}
				onPressCameraMute={this.onPressCameraMute}
				onPressChangeAudioRoute={this.onPressChangeAudioRoute}
				onPressSwitchVideo={this.onPressSwitchVideo}
				onPressOpenDoor={this.onPressOpenDoor}

				// --- for incoming view
				handleAnswerCall={this.handleAnswerCall}
				handleRejectCall={this.handleRejectCall}

				// --- ControlBttons states
				isMicMute={this.state.isMicMute}
				isCameraMute={this.state.isCameraMute}
				isToggleAudioDeviceOn={this.state.isToggleAudioDeviceOn}
				isKeyboardOpen={this.state.isKeyboardOpen}
				isHangUpBtnPressed={this.state.isHangUpBtnPressed}

				// --- DialPad onPress handler functions
				handleDialPadOnPress={this.handleDialPadOnPress}
				handleDialPadOnReset={this.handleDialPadOnReset}
				handleDialPadOnBackspace={this.handleDialPadOnBackspace}
				handleDialPadOnChangeText={this.handleDialPadOnChangeText}

				// --- Dialpad states
				dtmfs={this.state.dtmfs}
			/>
		);
	}

	renderFallbackNotInCallView() {
		return (
			<View style={styles.flexRow}>
				<Text>
					{ 'Canceling Call...' }
				</Text>
			</View>
		);
	}

	render() {
		//if (!this.isStart || this.props.parent.state.talkingCallData === null) {
		//	return this.renderFallbackNotInCallView();
		//}

		// --- check should we start timer
		if (!this.isTimerStarted && this.props.parent.state.session_call_start_time) {
			this.isTimerStarted = true;
			setTimeout(this.startCallTimer, 1000);
		}

		return (
			<View style={styles.screenContainer}>
				<View style={{flex: 1}}>
					{ this.renderCallInfo() }
				</View>
				<View style={{flex: 2}}>
					{ this.renderControlPanel() }
				</View>
			</View>
		);
	}
}

//@DebugLifeCycleDecorator
@autobind
class CallInfo extends React.Component {

	//constructor(props) {
	//	super(props);
	//}

	componentDidCatch(error, info) {
		xlog.info('CallInfo: componentDidCatch, error = ', error);
		xlog.info('CallInfo: componentDidCatch, info = ', info);
	}

	renderRealtimeCallInfo() {
		let { talkingCallInfo: callInfo } = this.props.parent.state;
		if (callInfo) {
			if (callInfo.calleeType === '1') {
				return (
					<View>
						<Text>
							{I18n.t('ExtraNetworkCall')}
						</Text>
					</View>
				);
			} else {
				return (
					<View>
						<Text style={{color: Theme.MainColor}}>
							{I18n.t('AhoyCall')}
						</Text>
					</View>
				);
			}
		}
	}

	renderCallInfoDesc() {
		// --- webcall only supports outgoing call
		return null;

		/*
		// --- only render for incoming
		if (this.props.callType !== 'incoming' || !this.props.parent.state.talkingCallData) {
			return null;
		}

		// --- apartment callinfo is no supported.
		if (this.props.parent.state.domainPage === 'apartment') {
			return null;
		}

		let { callInfo } = this.props.parent.state.talkingCallData;
		if (!callInfo) {
			return null;
		}

		//let desc = LIB.getTalkingCallInfoDesc(callInfo); // --- ZXC
		let desc = '';
		if (!desc) {
			return null;
		}

			//<View style={[styles.center, styles.flexRow, {marginTop: 20, flex: 0, maxWidth: AppSetup.dimensions.width * 0.9, padding: 5}]}>
		return (
			<View style={[styles.center, styles.flexRow, {marginTop: 20, flex: 0, padding: 5}]}>
				<Text style={{textAlign: 'center', color: 'grey', lineHeight: 30}}>
					{desc}
				</Text>
			</View>
		);
		*/
	}

	renderCurrentCallStateDescription() {
		let description = '';

		if (this.props.call_session_event === 'answered') {
			description = I18n.t('Talking');
		} else if (this.props.isHangUpBtnPressed) {
			description = I18n.t('CallEnding') + '...';
		} else {
			description = I18n.t('CallConnecting') + '...';
		}

		if (description) {
			return (
				<View>
					<Text>
						{description}
					</Text>
				</View>
			);
		}
	}

	renderCallDuration() {
		if (this.props.callDuration && this.props.callDuration !== 0) {
			//const callTypeIconName = (this.props.callType === 'incoming' ? 'call-received' : 'call-made');
			//const callTypeIconColor = (this.props.callType === 'incoming' ? Theme.MainColor : 'darkseagreen');
			//<MaterialIcon name={callTypeIconName} style={{color: callTypeIconColor, fontSize: normaliseText(30), marginRight: 10}}/>
			return (
				<View style={[styles.flexRow, {flex: 0, padding: 10}]}>
					<MaterialIcon name="call-made" color='darkseagreen' size={30} />
					<Text style={styles.callDurationText}>
						{LIB.formatCallDuration(this.props.callDuration)}
					</Text>
				</View>
			);
		}
	}

	renderWebWarning() {
		if (this.props.callDuration && this.props.callDuration !== 0) {
			//const callTypeIconName = (this.props.callType === 'incoming' ? 'call-received' : 'call-made');
			//const callTypeIconColor = (this.props.callType === 'incoming' ? Theme.MainColor : 'darkseagreen');
			//<MaterialIcon name={callTypeIconName} style={{color: callTypeIconColor, fontSize: normaliseText(30), marginRight: 10}}/>
			return (
				<View>
					<Text style={{color: 'red'}}>
						{'\n\n'}
						{I18n.t('WebWarning')}
					</Text>
				</View>
			);
		}
	}

	renderRemotePeerProfile() {
		let displayName = '';
		let displayNumber = '';
		//let thumbnailPath = '';

		displayName = this.props.calleeName;
		displayNumber = this.props.number;
		displayNumber = ''; // --- we do not display number
		//thumbnailPath = this.props.thumbnailPath;

					//<MaterialIcon name="person" size={64} style={styles.img}/>
				//<View style={[styles.flexColumn, {flex: 0, margin: 10, maxWidth: AppSetup.dimensions.width * .6}]}>
		return (
			<View style={[styles.flexRow, {flex: 1, margin: 10, padding: 10, }]}>
				<MaterialIcon name="person" size={30} />
				<View style={[styles.flexColumn, {flex: 1, margin: 10}]}>
					{ displayName !== '' &&
						<View>
							<Text numberOfLines={1} style={[styles.callInfoText]}>
								{ displayName }
							</Text>
						</View>
					}
					<View>
						<Text numberOfLines={1} style={[styles.callInfoText]}>
							{ displayNumber }
						</Text>
					</View>
				</View>
			</View>
		);
	}

	// --- Note: when use { condition && </View>, condition should not be a string or integer }
	// --- or will throw: Cannot add a child that doesn't have a YogaNode to a parent without a measure function
	render() {
		return (
			<View style={styles.controlPanel}>
				<View style={[styles.flexColumn, {flex: 3}]}>
					<View style={styles.flexColumn}>
						{ this.renderRemotePeerProfile() }
						{ this.renderCurrentCallStateDescription() }
						{ this.renderRealtimeCallInfo() }
						{ this.renderCallDuration() }
						{ this.renderWebWarning() }
						{ /*this.renderCallInfoDesc()*/ }
					</View>
				</View>
			</View>
		);
	}
}

class ControlPanel extends React.Component {

	//constructor(props) {
	//	super(props);
	//}

	componentDidCatch(error, info) {
		xlog.info('ControlPanel: componentDidCatch, error = ', error);
		xlog.info('ControlPanel: componentDidCatch, info = ', info);
	}

	renderTalkingControlPanel() {
		const buttonDisabled = this.props.isHangUpBtnPressed;
		const btnDisabledStyle = (buttonDisabled ? {color: Theme.ButtonDisabledColor} : {});
		const notAnsweredYet = (this.props.call_session_event !== 'answered') ? true : false;
		const notAnsweredYetBtnDisabledStyle = (notAnsweredYet ? {color: Theme.ButtonDisabledColor} : {});
		const micMuteStyle = (notAnsweredYet) ? {color: Theme.ButtonDisabledColor} : (this.props.isMicMute) ? styles.buttonIsOn : styles.buttonIsOff;
		const cameraButtonDisabled = false; // --- always can toggle
		const cameraMuteStyle = (cameraButtonDisabled) ? {color: Theme.ButtonDisabledColor} : (this.props.isCameraMute) ? styles.buttonIsOn : styles.buttonIsOff;
		//const callInfo = displayName + ' - ' + displayNumber;
		//const callTypeIconName = (this.props.callType === 'incoming' ? 'call-received' : 'call-made');
		//const callTypeIconColor = (this.props.callType === 'incoming' ? Theme.MainColor : 'darkseagreen');
		let isAudioDeviceOnStyle, selectedAudioDeviceIcon;
		//let audioButtonDisabled = (this.props.callType === 'incoming' && this.props.call_session_event !== 'answered') ? true : false;
		//let element = this.props.parent.remoteAudio;
		//let isChangeAudioRouteSupported = (typeof element.sinkId !== 'undefined' ? true : false);
		//let audioButtonDisabled = (!isChangeAudioRouteSupported || this.props.call_session_event !== 'answered') ? true : false;
		let audioButtonDisabled = (this.props.call_session_event !== 'answered') ? true : false;
		let audioButtonDisabledStyle = audioButtonDisabled ? {color: Theme.ButtonDisabledColor} : {};

		if (false) {
			if (this.props.parent.state.audioDeviceState !== null) {
				if (this.props.parent.state.audioDeviceState.selectedAudioDevice === 'EARPIECE') {
					isAudioDeviceOnStyle = styles.buttonIsOff;
					selectedAudioDeviceIcon = <MaterialIcon name="volume-up" size={30} style={[isAudioDeviceOnStyle, audioButtonDisabledStyle]} />
				} else if (this.props.parent.state.audioDeviceState.selectedAudioDevice === 'SPEAKER_PHONE') {
					isAudioDeviceOnStyle = styles.buttonIsOn;
					selectedAudioDeviceIcon = <MaterialIcon name="volume-up" size={30} style={[isAudioDeviceOnStyle, audioButtonDisabledStyle]} />
				} else if (this.props.parent.state.audioDeviceState.selectedAudioDevice === 'BLUETOOTH') {
					isAudioDeviceOnStyle = styles.buttonIsOn;
					selectedAudioDeviceIcon = <MaterialIcon name="bluetooth-audio" size={30} style={[isAudioDeviceOnStyle, audioButtonDisabledStyle]} />
				} else if (this.props.parent.state.audioDeviceState.selectedAudioDevice === 'WIRED_HEADSET') {
					isAudioDeviceOnStyle = styles.buttonIsOn;
					selectedAudioDeviceIcon = <MaterialIcon name="headset" size={30} style={[isAudioDeviceOnStyle, audioButtonDisabledStyle]} />
				}
			} else {
				// --- android would be ready soon. this is just a temporary pre-render according to media type, prevent press if not ready.
				audioButtonDisabled = true;
				audioButtonDisabledStyle = {color: Theme.ButtonDisabledColor};
				isAudioDeviceOnStyle = (this.props.parent.state.shouldUseVideo ? styles.buttonIsOn : styles.buttonIsOff);
				selectedAudioDeviceIcon = <MaterialIcon name="volume-up" size={30} style={[isAudioDeviceOnStyle, audioButtonDisabledStyle]} />
			}
		} else {
			// --- ios
			if (this.props.isToggleAudioDeviceOn === null) {
				isAudioDeviceOnStyle = (this.props.parent.state.shouldUseVideo ? styles.buttonIsOn : styles.buttonIsOff); // --- not pressed yet, decide by video type
			} else {
				isAudioDeviceOnStyle = (this.props.isToggleAudioDeviceOn ? styles.buttonIsOn : styles.buttonIsOff); // --- pressed, can be determine directly
			}
			selectedAudioDeviceIcon = <MaterialIcon name="volume-up" size={30} style={[isAudioDeviceOnStyle, audioButtonDisabledStyle]} />
		}

		// --- we want to render openDoor at the middle. so need checks video buttons together.
		let secondControlButtons = [];
		if (this.props.parent.state.shouldUseVideo) {
			secondControlButtons.push((
				<ControlButton
					icon = <MaterialIcon name="switch-video" size={30} style={btnDisabledStyle} />
					disabled={buttonDisabled}
					onPress={this.props.onPressSwitchVideo}
				/>
			));
		}
		if (this.props.parent.state.talkingCallInfo && this.props.parent.state.talkingCallInfo && this.props.parent.state.talkingCallInfo.supportOpenDoor === '1') {
			secondControlButtons.push((
				<ControlButton
					icon = <MaterialIcon name="lock-open" size={30} style={[notAnsweredYetBtnDisabledStyle, btnDisabledStyle]} />
					disabled={notAnsweredYet || buttonDisabled}
					onPress={this.props.onPressOpenDoor}
				/>
			));
		}
		if (this.props.parent.state.shouldUseVideo) {
			secondControlButtons.push((
				<ControlButton
					icon = <MaterialIcon name="videocam-off" size={30} style={[cameraMuteStyle, btnDisabledStyle]} />
					disabled={cameraButtonDisabled || buttonDisabled}
					onPress={this.props.onPressCameraMute}
				/>
			));
		}

		/*
		// --- this is a test button for testing some hacks and workaround like mute/unmute, especially on ios safari...
		secondControlButtons.push((
			<ControlButton
				key="play-arrow"
				icon = <MaterialIcon name="play-arrow" size={30} style={styles.mainButtonContainer} />
				onPress={() => {
					console.log('ZXCPOIU: muted: ', this.props.parent.remoteAudio.muted);
					this.props.parent.remoteAudio.muted = true;
					console.log('ZXCPOIU: muted: ', this.props.parent.remoteAudio.muted);
					this.props.parent.remoteAudio.muted = false;
					console.log('ZXCPOIU: muted: ', this.props.parent.remoteAudio.muted);

					//this.props.parent.remoteAudio.play()
					//.then((info) => {
					//	xlog.info("ZXCPOIU TALK play() SUCCESS, info = ", info);
					//})
					//.catch((err) => {
					//	xlog.info("ZXCPOIU TALK play() was rejected, err = ", err);
					//});
				}}
			/>
		));
		*/

		return (
			<View style={styles.controlPanel}>
				<View style={styles.controlButtonRow}>
					<ControlButton
						icon = <MaterialIcon name="dialpad" size={30} style={btnDisabledStyle} />
						disabled={(false && buttonDisabled)}
						onPress={this.props.handleOnKeyboardSwitch}
					/>

					<ControlButton
						icon = <MaterialIcon name="mic-off" size={30} style={[micMuteStyle, btnDisabledStyle]} />
						disabled={notAnsweredYet || buttonDisabled}
						onPress={this.props.onPressMicMute}
					/>

					<ControlButton
						icon={selectedAudioDeviceIcon}
						disabled={audioButtonDisabled || buttonDisabled}
						onPress={this.props.onPressChangeAudioRoute}
						iconStyle={[isAudioDeviceOnStyle, audioButtonDisabledStyle]}
					/>

				</View>

				{ secondControlButtons.length > 0 &&
					<View style={styles.controlButtonRow}>
						{secondControlButtons}
					</View>
				}

				<View style={styles.controlButtonRow}>
					<ControlButton
						icon = <MaterialIcon name="call-end" size={30} color='#FAFAFA' />
						disabled={this.props.isHangUpBtnPressed}
						onPress={this.props.handleHangUp}
						containerStyle={styles.hangupButtonContainer}
					/>
				</View>
			</View>
		);
	}

	renderDialPad() {
		return (
			<View style={styles.controlPanel}>
				<View style={{flex: 1}}>
					<DialPad
						ignoreLongPress={true}
						dialedNumber={this.props.dtmfs}
						handleOnPress={this.props.handleDialPadOnPress}
						handleOnReset={this.props.handleDialPadOnReset}
						handleOnBackspace={this.props.handleDialPadOnBackspace}
						handleOnChangeText={this.props.handleDialPadOnChangeText}
						handleOnKeyboardSwitch={this.props.handleOnKeyboardSwitch}
						renderCountryPicker={false}
						renderKeyboardSwitchButton={true}
					/>
				</View>
				<View style={{alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column'}}>
					<ControlButton
						icon = <MaterialIcon name="call-end" size={30} color='#FAFAFA' />
						disabled={this.props.isHangUpBtnPressed}
						onPress={this.props.handleHangUp}
						containerStyle={[styles.hangupButtonContainer, {height: 60, width: 60, borderRadius: 30}]}
					/>
				</View>
			</View>
		)
	}


	render() {
		if (this.props.isKeyboardOpen) {
			return this.renderDialPad();
		} else {
			return this.renderTalkingControlPanel();
		}
	}
}

//@DebugLifeCycleDecorator
@autobind
class ControlButton extends React.Component {

	//constructor(props) {
	//	super(props);
	//}

	componentDidCatch(error, info) {
		xlog.info('ControlButton: componentDidCatch, error = ', error);
		xlog.info('ControlButton: componentDidCatch, info = ', info);
	}

	static defaultProps = {
		disabled: false,
		onPress: () => {},
		icon: null,
	};

	render() {
		let containerStyle = styles.controlButtonContainer;

		if (this.props.containerStyle) {
			if (Array.isArray(this.props.containerStyle)) {
				containerStyle = [styles.controlButtonContainer, ...this.props.containerStyle];
			} else {
				containerStyle = [styles.controlButtonContainer, this.props.containerStyle];
			}
		}

		return (
			<ButtonElement
				disabled={ !!this.props.disabled }
				onPress={ this.props.onPress }
				buttonStyle={containerStyle}
				disabledStyle={{backgroundColor: '#FAFAFA'}}
				icon={ this.props.icon }
			/>
		);
	}
}

const styles = StyleSheet.create({
	screenContainer: {
		flex: 1,
		flexDirection: "column",
		paddingHorizontal: 10,
		paddingVertical: 10,
		backgroundColor: '#FAFAFA',
	},
	screenContainerVideo: {
		flex: 1,
		flexDirection: "column",
		backgroundColor: 'black',
	},
	hangupButtonContainer: {
		height: 64,
		width: 64,
		borderRadius: 32,
		borderWidth: 1,
		alignItems: 'center',
		justifyContent: 'center',
		borderColor: 'red',
		backgroundColor: 'red',
	},
	hangupButtonText: {
		//textAlign: 'center',
		//fontSize: 30,
		//color: "#FAFAFA",
	},
	controlButtonContainer: {
		height: 64,
		width: 64,
		borderRadius: 32,
		borderWidth: 1,
		borderColor: 'darkgray',
		margin: 10,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#FAFAFA',
	},
	controlButtonText: {
		//textAlign: 'center',
		//fontSize: 30,
		backgroundColor: 'green',
	},
	answerButtonContainer: {
		height: 64,
		width: 64,
		borderRadius: 32,
		borderWidth: 1,
		alignItems: 'center',
		justifyContent: 'center',
		borderColor: Theme.MainColor,
		backgroundColor: Theme.MainColor,
	},
	answerButtonText: {
		//textAlign: 'center',
		//fontSize: 30,
		//color: "#FAFAFA",
	},
	flexColumn: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	flexRow: {
		flex:1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	callInfoText: {
		fontSize: 20,
	},
	callDurationText: {
		fontSize: 20,
	},
	img: {
		height: 64,
		width: 64,
		borderRadius: 32,
		borderWidth: 1,
		borderColor: 'gainsboro',
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonIsOn: {
		color: Theme.MainColor,
	},
	buttonIsOff: {
	},
	controlButtonRow: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center',
	},
	controlPanel: {
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-end',
		backgroundColor: 'rgba(255, 255, 255, 0.8)', // --- same with Theme.BackgroundColor #FAFAFA, do not use opacity: 0.7, it will decrease text as well
	},
	mainButtonContainer: {
		borderRadius: 30,
		borderWidth: 0,
		backgroundColor: Theme.MainColor,
	},
});

export default Talk;
