// --- Returns if a value is an object
export function isObject(value) {
    return value && typeof value === 'object' && value.constructor === Object;
}

export function timerPad(num) {
    return ("0" + num).slice(-2);
}

export function formatCallDuration(secs) {
    var minutes = Math.floor(secs / 60);
    var hours = Math.floor(minutes / 60)
    secs = secs % 60;
    minutes = minutes % 60;
    return timerPad(hours) + ":" + timerPad(minutes) + ":" + timerPad(secs);
}

