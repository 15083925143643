import React from 'react';
import {
	View,
	Text,
	StyleSheet,
	//TextInput,
	//TouchableHighlight,
	TouchableOpacity,
} from 'react-native-web';
import PropTypes from 'prop-types';
import MaterialIcon from 'react-native-vector-icons/dist/MaterialIcons';

import AppSetup from '../app_setup.js';

import {
	Button as ButtonElement,
} from 'react-native-elements';

import { MaterialTheme } from '..//lib/styles.js';
const Theme = MaterialTheme[AppSetup.Theme];
const xlog = require('../lib/xlog.js'); // --- use reqiure so that we can use `pure_funcs` to strip in production build

export default class DialPad extends React.Component {

	constructor(props) {
		super(props)
		this.handleOnPress = this.handleOnPress.bind(this);
		this.handleOnReset = this.handleOnReset.bind(this);
		this.handleOnBackspace = this.handleOnBackspace.bind(this);
		this.handleOnChangeText = this.handleOnChangeText.bind(this);
		this.renderResetButton = this.renderResetButton.bind(this);
		this.state = {
			textInputHeight: 0,
		};
	}

	componentDidCatch(error, info) {
		xlog.info('DialPad: componentDidCatch, error = ', error);
		xlog.info('DialPad: componentDidCatch, info = ', info);
	}

	static propTypes = {
		handleOnPress: PropTypes.func.isRequired,
		handleOnReset: PropTypes.func.isRequired,
		handleOnBackspace: PropTypes.func.isRequired,
		handleOnChangeText: PropTypes.func.isRequired,
		dialedNumber: PropTypes.string.isRequired,
		cca2: PropTypes.string,
		countryCallingCode: PropTypes.string,
		countryAvailableList: PropTypes.array,
		renderCountryPicker: PropTypes.bool,
		renderKeyboardSwitchButton: PropTypes.bool,
		handleOnKeyboardSwitch: PropTypes.func,
	};

	static defaultProps = {
		renderCountryPicker: true,
	};

	handleOnPress(button, isLongPress) {
		if (isLongPress && this.props.ignoreLongPress === true) {
			return;
		}
		this.props.handleOnPress(button, isLongPress);
	}

	handleOnReset() {
		this.props.handleOnReset();
	}

	handleOnBackspace() {
		this.props.handleOnBackspace();
	}

	handleOnChangeText(text) {
		this.props.handleOnChangeText(text);
	}

	renderResetButton() {
		let iconColor = (this.props.dialedNumber ? {} : {color: Theme.BackgroundColor.backgroundColor});
		return (
			<ButtonElement
				onPress={ this.handleOnReset }
				buttonStyle={ styles.dialInputResetButtonContainer }
				icon=<MaterialIcon name="close" size={30} style={iconColor} />
			/>
		);
	}

	getCountryCallingCodeText() {
		let prefix = '';
		if (this.props.cca2 === 'AHOY') {
			return '';
		} else {
			prefix = '+';
		}

		return prefix + this.props.countryCallingCode
	}

	/*
						<TextInput
							style={[
								styles.dialInputNumbersText,
								{
									height: Math.max(44, this.state.textInputHeight),
									textAlign: 'center',
								}
							]}
							defaultValue={this.props.dialedNumber}
							onChangeText={this.handleOnChangeText}
							onContentSizeChange={(event) => {
								this.setState({height: event.nativeEvent.contentSize.height});
							}}
							numberOfLines={1}
							underlineColorAndroid='transparent'
							keyboardType='phone-pad'
							returnKeyType='done'
							allowFontScaling={false}
							editable={false}
						/>
	*/
	render() {
		return (
			<View style={styles.mainContainer}>
				<View style={styles.infoViewContainer}>
					<ButtonElement
						onPress={ this.props.handleOnKeyboardSwitch }
						buttonStyle={ styles.controlButtonContainer }
						icon=<MaterialIcon name="expand-more" size={40} />
					/>
					<View style={styles.dialInputNumbersContainer}>
						<Text
							numberOfLines={1}
							allowFontScaling={false}
							style={styles.dialInputNumbersText}
						>
							{this.props.dialedNumber}
						</Text>
					</View>
					{this.renderResetButton()}
				</View>
				<View style={styles.dialPadViewContainer}>
					<DialPadCore handleOnPress={this.handleOnPress} />
				</View>
			</View>
		)
	}
}

class DialButton extends React.Component {

	static displayName = "DialButton";
	static key = "dial_button";

	componentDidCatch(error, info) {
		xlog.info('DialButton: componentDidCatch, error = ', error);
		xlog.info('DialButton: componentDidCatch, info = ', info);
	}

	/*
	handleMouseDown(e) {
		this.setState({active: true})
	}
	handleMouseUp(e) {
		if (this.state.active) {
			this.setState({active: false})
		}
	}
	*/

	render() {
		const { button, handleOnPress } = this.props;
		const { symbol, alias, icon } = button;
		return (
			<TouchableOpacity
				onPress={() => handleOnPress(symbol)}
				onLongPress={() => handleOnPress(alias, true)}
				delayLongPress={1000}
				underlayColor={Theme.SecondaryColor}
				style={styles.dialButtonTouchableContainer}
			>
				<View style={styles.dialButtonContainer}>
					<Text style={[styles.dialButtonText, {marginLeft: (alias ? 10 : 0)}]} allowFontScaling={false}>
						{icon ? icon : symbol}
					</Text>
					{!!alias &&
						<Text style={styles.dialButtonTextAlias} allowFontScaling={false}>{alias}</Text>
					}
				</View>
			</TouchableOpacity>
		);
	}
}

class DialPadCore extends React.Component {

	static displayName = "DialPadCore";
	static key = "_dial_pad";

	componentDidCatch(error, info) {
		xlog.info('DialButton: componentDidCatch, error = ', error);
		xlog.info('DialButton: componentDidCatch, info = ', info);
	}

	render() {
		const { handleOnPress } = this.props;

		const buttons = {
			'1': {
				symbol : '1'
			},
			'2': {
				symbol : '2',
				//alias	: 'abc'
			},
			'3': {
				symbol : '3',
				//alias	: 'def'
			},
			'4': {
				symbol : '4',
				//alias	: 'ghi'
			},
			'5': {
				symbol : '5',
				//alias	: 'jkl'
			},
			'6': {
				symbol : '6',
				//alias	: 'mno'
			},
			'7': {
				symbol : '7',
				//alias	: 'pqrs'
			},
			'8': {
				symbol : '8',
				//alias	: 'tuv'
			},
			'9': {
				symbol : '9',
				//alias	: 'wxyz'
			},
			'*': {
				symbol : '*'
			},
			'0': {
				symbol : '0',
				alias	: '+'
			},
			'#': {
				symbol : '#'
			},
			'+': {
				symbol : '+'
			},
			/*
			'call': {
				//icon : (<i className='fa fa-phone' />),
				//icon : (<FontAwesome name='fa fa-phone' />),
				icon: <Icon name="dialer-sip"/>,
				symbol : '',
				action : 'call'
			},
			'hangup': {
				//icon : (<i className='fa fa-remove' />),
				//icon : (<FontAwesome name='fa fa-remove' />),
				icon: <Icon name="call-end"/>,
				symbol : '',
				action : 'hangup'
			}
			*/
		};
		return (
			<View style={styles.dialPadContainer}>
				<View style={styles.dialPadRow}>
					<DialButton button={buttons['1']} handleOnPress={handleOnPress} />
					<DialButton button={buttons['2']} handleOnPress={handleOnPress} />
					<DialButton button={buttons['3']} handleOnPress={handleOnPress} />
				</View>
				<View style={styles.dialPadRow}>
					<DialButton button={buttons['4']} handleOnPress={handleOnPress} />
					<DialButton button={buttons['5']} handleOnPress={handleOnPress} />
					<DialButton button={buttons['6']} handleOnPress={handleOnPress} />
				</View>
				<View style={styles.dialPadRow}>
					<DialButton button={buttons['7']} handleOnPress={handleOnPress} />
					<DialButton button={buttons['8']} handleOnPress={handleOnPress} />
					<DialButton button={buttons['9']} handleOnPress={handleOnPress} />
				</View>
				<View style={styles.dialPadRow}>
					<DialButton button={buttons['*']} handleOnPress={handleOnPress} />
					<DialButton button={buttons['0']} handleOnPress={handleOnPress} />
					<DialButton button={buttons['#']} handleOnPress={handleOnPress} />
				</View>
			</View>
		)
	}
}


// --- If you want to use Higher Order Component, just import it and bind to a proper scope(this), and pass handled dialedNumber in.
// --- You need to implement handleDialPadOnChangeText() method which handles state changes. then pass you state into impl functions.
/*
export const DEFAULT_STATUS_KEY = 'numbers';

export function handleOnChangeText(text, k=DEFAULT_STATUS_KEY) {
	this.setState({[k]: text});
}
*/
export function handleOnPress(symbol, isLongPress, dialedNumber) {
	if (!symbol) {
		return null;
	}
	let numbers;
	if (isLongPress) {
		numbers = dialedNumber.slice(0, -1);
	} else{
		numbers = dialedNumber;
	}
	this.handleDialPadOnChangeText(`${numbers}${symbol}`);
	return symbol;
}

export function handleOnBackspace(dialedNumber) {
	this.handleDialPadOnChangeText(dialedNumber.slice(0, -1));
}

export function handleOnReset() {
	this.handleDialPadOnChangeText('');
}

const styles = StyleSheet.create({
	mainContainer: {
		flexDirection: 'column',
		flex: 1,
	},
	infoViewContainer: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	dialPadViewContainer: {
		flex: 1,
	},
	dialButtonTouchableContainer: {
		height: 50,
		width: 50,
		//borderRadius: 30,
	},
	dialButtonContainer: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		//margin: 5,
		//padding: 5,
	},
	dialButtonText: {
		fontSize: 25,
		textAlign: 'center',
	},
	dialButtonTextAlias: {
		fontSize: 15,
		fontWeight: 'normal',
	},
	dialPadContainer: {
		flexDirection: 'column',
		flex: 1,
		margin: 1,
	},
	dialPadRow: {
		flexDirection: 'row',
		justifyContent: 'space-around',
		margin: 5,
		flex: 1,
	},
	countryPickerContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 10,
	},
	countryPickerText: {
		flex: 0,
		color: 'grey',
		fontSize: 16,
		//marginLeft: 1,
	},
	dialInputNumbersContainer: {
		flexDirection: 'row',
		flex: 1,
		marginLeft: 5,
		justifyContent: 'center',
		alignItems: 'center',
	},
	dialInputResetButtonContainer: {
		paddingLeft: 10,
		paddingRight: 10,
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#FFFFFF',
	},
	controlButtonContainer: {
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#FFFFFF',
	},
	dialInputNumbersText: {
		flex: 1,
		fontSize: 25,
		textAlign: 'left',
		color: Theme.MainColor,
		padding: 0,
	},
});
