import React from 'react';
import {
	View,
} from 'react-native-web';

const layoutStyle = {
	margin: 5,
	padding: 5,
	borderWidth: 1,
	borderColor: '#DDD',
	borderStyle: 'solid',
	display: 'flex',
	flexDirection: "column",
	alignItems: 'center',
	justifyContent: 'center',
}

class Layout extends React.PureComponent {

	render() {
		return (
			<View style={layoutStyle}>
				{this.props.children}
			</View>
		);
	}
}

export default Layout
