import AppSetup from '../app_setup.js';

const logToConsole = (typeof AppSetup.logToConsole === 'boolean' ? AppSetup.logToConsole : false);

const Log = require('console-log-level')({
	level: (logToConsole ? 'info' : 'fatal'),
});

function log(level, args) {
	if (logToConsole) {
		Log[level].apply(null, args);
	}
}

const xlog = {
	trace: function() {
		log('trace', arguments);
	},
	debug: function() {
		log('debug', arguments);
	},
	info: function() {
		log('info', arguments);
	},
	warn: function() {
		log('warn', arguments);
	},
	error: function() {
		log('error', arguments);
	},
	fatal: function() {
		log('fatal', arguments);
	},
}

// --- we use traditional `require()` instead `import()` to use this `xlog` lib,
// --- this is because we want to use webpack's terser to strip log functions in the prodution bundle via `pure_funcs` under minify
// --- but it does not support strip `import module` but `require` does the trick, maybe it is due to that webpack
// --- evaluate `import` first and once which will change the value to `x.default.info()`
module.exports = xlog;

/*
class xlog {
	static log(level, args) {
		if (logToConsole) {
			Log[level].apply(null, args);
		}
	}

	static trace() {
		xlog.log('trace', arguments);
	}

	static debug() {
		xlog.log('debug', arguments);
	}

	static info() {
		xlog.log('info', arguments);
	}

	static warn() {
		xlog.log('warn', arguments);
	}

	static error() {
		xlog.log('error', arguments);
	}

	static fatal() {
		xlog.log('fatal', arguments);
	}
}

export default xlog;
*/

