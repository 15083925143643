//const MainColor = '#71AAE3';
const MainColor = '#658AF9';
const ComplementaryColor = '#F9D465';
//const SecondaryColor = '#DCEDFC';
//const SecondaryColor = '#E4F4FF';
const SecondaryColor = '#E1E8FE';
//const SeparatorColor = '#D3E2F1';
const SeparatorColor = 'lightgrey';
//const UnderlayColor = '#EDF6FD';
const UnderlayColor = '#E8ECF7';
const SearchBarColor = '#E4E4E4';
const FacebookColor = '#3B5998';
const GoogleColor = '#DB3236';
const TabBarIconColor = '#FFFFFF';
//const LinkColor = '#2AAFAB';
const LinkColor = '#7E9DFA';
//const ButtonBackgroundColor = '#96BDEB';
const ButtonBackgroundColor = '#658AF9';
const ButtonDisabledColor = '#DCDCDC';
//const ErrorColor = '#EF6C61';
const ErrorColor = 'indianred';
const ColorCollection = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#FF5722', '#795548', '#9E9E9E', '#607D8B'];
const ChannelAdminNavBarColor = '#657bc0';

var MaterialTheme = {
    light: {
        MainColor,
        ComplementaryColor,
        SecondaryColor,
        SeparatorColor,
        UnderlayColor,
        SearchBarColor,
        FacebookColor,
        GoogleColor,
        TabBarIconColor,
        LinkColor,
        ButtonBackgroundColor,
        ButtonDisabledColor,
        ErrorColor,
        ColorCollection,
        ChannelAdminNavBarColor,
        TextColor: {
            color: '#000000',
        },
        PrimaryTextOpacity: {
            opacity: 0.87,
        },
        SecondaryTextOpacity: {
            opacity: 0.54,
        },
        HintTextOpacity: {
            opacity: 0.38,
        },
        DividersTextOpacity: {
            opacity: 0.12,
        },
        PrimaryText: {
            //opacity: 0.87,
            color: '#000000DE',
        },
        SecondaryText: {
            //opacity: 0.54,
            color: '#0000008A',
        },
        HintText: {
            //opacity: 0.38,
            color: '#00000061',
        },
        DividersText: {
            //opacity: 0.12,
            color: '#0000001F',
        },
        StatusBarBackgroundColor: {
            backgroundColor: '#E0E0E0',
        },
        AppBarBackgroundColor: {
            backgroundColor: '#F5F5F5',
        },
        BackgroundColor: {
            backgroundColor: '#FAFAFA',
        },
        DialogBackgroundColor: {
            backgroundColor: '#FFFFFF',
        },
        NavBarBackButtonText: {
            color: 'white',
        },
        NavBarBackButtonImage: {
            tintColor: 'white',
        },
        TabBar: {
            backgroundColor: 'white',
            borderTopWidth: 1,
            borderColor: '#e2e2e2',
        },
    },
    dark: {
        TextColor: {
            color: '#FFFFFF',
        },
        PrimaryTextOpacity: {
            opacity: 1,
        },
        SecondaryTextOpacity: {
            opacity: 0.70,
        },
        HintTextOpacity: {
            opacity: 0.50,
        },
        DividersTextOpacity: {
            opacity: 0.12,
        },
        PrimaryText: {
            //opacity: 1,
            color: '#FFFFFFFF',
        },
        SecondaryText: {
            //opacity: 0.70,
            color: '#FFFFFFB3',
        },
        HintText: {
            //opacity: 0.50,
            color: '#FFFFFF80',
        },
        DividersText: {
            //opacity: 0.12,
            color: '#FFFFFF1F',
        },
        StatusBarBackgroundColor: {
            backgroundColor: '#000000',
        },
        AppBarBackgroundColor: {
            backgroundColor: '#212121',
        },
        BackgroundColor: {
            backgroundColor: '#303030',
        },
        DialogBackgroundColor: {
            backgroundColor: '#424242',
        },
    }
};

MaterialTheme['dark'] = {...MaterialTheme['light'], ...MaterialTheme['dark']};

export { MaterialTheme };

/*
opacity mapping to HEX

100% — FF
99% — FC
98% — FA
97% — F7
96% — F5
95% — F2
94% — F0
93% — ED
92% — EB
91% — E8
90% — E6
89% — E3
88% — E0
87% — DE
86% — DB
85% — D9
84% — D6
83% — D4
82% — D1
81% — CF
80% — CC
79% — C9
78% — C7
77% — C4
76% — C2
75% — BF
74% — BD
73% — BA
72% — B8
71% — B5
70% — B3
69% — B0
68% — AD
67% — AB
66% — A8
65% — A6
64% — A3
63% — A1
62% — 9E
61% — 9C
60% — 99
59% — 96
58% — 94
57% — 91
56% — 8F
55% — 8C
54% — 8A
53% — 87
52% — 85
51% — 82
50% — 80
49% — 7D
48% — 7A
47% — 78
46% — 75
45% — 73
44% — 70
43% — 6E
42% — 6B
41% — 69
40% — 66
39% — 63
38% — 61
37% — 5E
36% — 5C
35% — 59
34% — 57
33% — 54
32% — 52
31% — 4F
30% — 4D
29% — 4A
28% — 47
27% — 45
26% — 42
25% — 40
24% — 3D
23% — 3B
22% — 38
21% — 36
20% — 33
19% — 30
18% — 2E
17% — 2B
16% — 29
15% — 26
14% — 24
13% — 21
12% — 1F
11% — 1C
10% — 1A
9% — 17
8% — 14
7% — 12
6% — 0F
5% — 0D
4% — 0A
3% — 08
2% — 05
1% — 03
0% — 00
*/
