import SDPTransform from 'sdp-transform';

const xlog = require('./xlog.js');

export function customSdpHacks(sdp) {
	// --- NOTE: reject will terminates the whole call, so only use resolve. means skip if error.
	return new Promise((resolve, reject) => {
		try {
			// --- webrtc will pass as {sdp: '', type: 'offer'}
			let typeof_sdp = typeof sdp;
			let sdpStr = '';
			let sdpType = 'unknow';

			if (typeof_sdp === 'string') {
				sdpStr = sdp;
			} else if (typeof_sdp === 'object' && typeof sdp.sdp === 'string' ) {
				sdpStr = sdp.sdp;
				sdpType = sdp.type || '';
			} else {
				xlog.info('customSdpHacks(): unknow sdp type.', sdp);
				resolve(sdp);
				return;
			}

			// --- webcall only cares about outgoing offer, since we do not accept incoming call, so do not bother 'answer' sdp
			if (sdpType !== 'offer') {
				xlog.info('customSdpHacks(): sdp type "%s" is not "offer". skip.', sdpType);
				resolve(sdp);
				return;
			}

			xlog.info('customSdpHacks(): original sdp: type = %s, sdpType = %s, sdp = \n\n%s', typeof_sdp, sdpType, sdpStr.replace('\\r\\n', '\n'));

			let sdpObj = SDPTransform.parse(sdpStr);

			if (!sdpObj.media) {
				resolve(sdp);
				return;
			}

			let m = -1;
			m = getMediaByType(sdpObj.media, 'audio');
			if (m < 0) {
				resolve(sdp);
				return;
			}

			// --- for audio
			let audio = sdpObj.media[m];
			let allowCodecName = ['opus', 'pcmu']; // --- allowed codec name in order
			let codecNameToCode = getCodecPayloadMap(audio, allowCodecName);
			let allowCodecCode = [];
			for (let c of allowCodecName) {
				if (c in codecNameToCode) {
					allowCodecCode.push(codecNameToCode[c]);
				}
			}
			setPreferCodecOnly(audio, allowCodecCode);

			if ('opus' in codecNameToCode) {
				// --- set opus to match with asterisk
				let opusFmtpConfig = {
					'sprop-maxcapturerate': 16000,
					'maxplaybackrate': 16000,
					'maxaveragebitrate': 20000,
					'minptime': 20,
					'maxptime': 20,
					'ptime': 20,
					'cbr': 1,
					'useinbandfec': 1,
				}
				setCustomFmtp(audio, codecNameToCode['opus'], opusFmtpConfig, true);
			}

			/*
			// --- set custom fmtp, is for opus to limit on max bit rate, the purpose is to save bandwidth
			let customFmtpConfig = {
				'sprop-maxcapturerate': 16000,
				'maxplaybackrate': 16000,
			}
			setCustomFmtp(media, payload, customFmtpConfig, true);
			*/

			/*
			// --- set new ssrc
			let objSsrc = {
				id: 12345678,
				attribute: 'foo',
				value: 'bar',
			};
			sdpObj.media[0].ssrcs.push(objSsrc);
			*/

			let nextSdpStr = SDPTransform.write(sdpObj);
			if (typeof_sdp === 'string') {
				resolve(nextSdpStr);
				return;
			} else {
				sdp.sdp = nextSdpStr;
				resolve(sdp);
				return;
			}
		} catch (err) {
			xlog.info('customSdpHacks(): exception: ', err);
			resolve(sdp);
			return;
		}
	});
}

export function getMediaByType(media, type) {
	if (!Array.isArray(media) || media.length <= 0) {
		return -1;
	}

	let m = -1;
	for (let i = 0; i < media.length; i++) {
		if (media[i].type === type) {
			m = i;
			break;
		}
	}
	return m;
}

export function getCodecPayloadMap(media, allowCodecName) {
	if (!media || !media.rtp || !Array.isArray(media.rtp) || media.rtp.length <= 0) {
		return -1
	}
	let payload = -1;
	let i = media.rtp.length;
	let codecNameToCode = {}
	while (i--) {
		let codec = media.rtp[i].codec;
		if (codec) {
			codec = codec.toLowerCase();
			if (allowCodecName.includes(codec)) {
				payload = media.rtp[i].payload;
				codecNameToCode[codec] = payload; // --- ex: opus => 101
			}
		}
	}
	return codecNameToCode;
}

export function setPreferCodecOnly(media, allowCodecCode) {
	if (!media) { return -1; }
	let params = ['rtp', 'fmtp', 'rtcpFb'];

	params.forEach((param, index, array) => {
		if (Array.isArray(media[param]) && media[param].length > 0) {
			let i = media[param].length;
			while (i--) {
				if (!allowCodecCode.includes(media[param][i].payload)) {
					media[param].splice(i, 1);
				}
			}
		}
	});

	media.payloads = allowCodecCode.join(' ');
}

export function setCustomFmtp(media, codecCode, myConfig, override) {
	if (!codecCode || !myConfig) {
		return;
	}
	if (!media || !media.fmtp || !Array.isArray(media.fmtp) || media.fmtp.length <= 0) {
		return;
	}

	let {config, index} = getFmtpConfigWithIndex(media, codecCode);
	if (!config) {
		return;
	}
	let newConfig;
	if (override) {
		newConfig = {...config, ...myConfig};
	} else {
		newConfig = {...myConfig, ...config};
	}

	let fmtpStr = convertFmtpObjToStr(newConfig);
	if (!fmtpStr) {
		return;
	}

	media.fmtp[index].config = fmtpStr;
}

export function getFmtpConfigWithIndex(media, codecCode) {
	if (!media || !media.fmtp || !Array.isArray(media.fmtp) || media.fmtp.length <= 0) {
		return {};
	}

	let config = null;
	let index = -1;
	for (let i = 0; i < media.fmtp.length; i++) {
		if (media.fmtp[i].payload === codecCode) {
			try {
				config = SDPTransform.parseFmtpConfig(media.fmtp[i].config);
				index = i;
				break;
			} catch (err) {
				return {};
			}
		}
	}
	return {config, index};
}

export function convertFmtpObjToStr(fmtpObj) {
	let configArray = [];
	for (let key in fmtpObj) {
		if (fmtpObj.hasOwnProperty(key)) {
			configArray.push(key.toString() + '=' + fmtpObj[key].toString());
		}
	}
	if (configArray.length <= 0) {
		return '';
	} else {
		return configArray.join(';');
	}
}

/*
var sdpStr = "v=0\r\n\
o=- 3058226124508359283 3 IN IP4 127.0.0.1\r\n\
s=-\r\n\
t=0 0\r\n\
a=group:BUNDLE audio\r\n\
a=msid-semantic: WMS e9816adf-013c-4532-a144-d5b87e433c1d\r\n\
m=audio 43027 UDP/TLS/RTP/SAVPF 111 103 9 102 0 8 105 13 126\r\n\
c=IN IP4 60.250.125.1\r\n\
a=rtcp:50388 IN IP4 60.250.125.1\r\n\
a=candidate:1912282794 1 udp 2122260223 192.168.1.145 33747 typ host generation 0 network-id 3 network-cost 10\r\n\
a=candidate:1912282794 2 udp 2122260222 192.168.1.145 41108 typ host generation 0 network-id 3 network-cost 10\r\n\
a=candidate:4038268958 1 udp 1686052607 60.250.125.1 43027 typ srflx raddr 192.168.1.145 rport 33747 generation 0 network-id 3 network-cost 10\r\n\
a=candidate:4038268958 2 udp 1686052606 60.250.125.1 50388 typ srflx raddr 192.168.1.145 rport 41108 generation 0 network-id 3 network-cost 10\r\n\
a=candidate:1064886874 1 tcp 1518280447 192.168.1.145 54054 typ host tcptype passive generation 0 network-id 3 network-cost 10\r\n\
a=candidate:1064886874 2 tcp 1518280446 192.168.1.145 34246 typ host tcptype passive generation 0 network-id 3 network-cost 10\r\n\
a=ice-ufrag:ywc/\r\n\
a=ice-pwd:qMQPSC7XRXQn9jQB6ZHFEa/0\r\n\
a=fingerprint:sha-256 5E:1F:8D:80:00:83:99:AD:B1:71:48:91:08:00:45:ED:67:B2:7F:37:7F:52:BD:6E:EA:AC:3A:8E:7C:46:01:B0\r\n\
a=setup:actpass\r\n\
a=mid:audio\r\n\
a=extmap:1 urn:ietf:params:rtp-hdrext:ssrc-audio-level\r\n\
a=extmap:3 http://www.webrtc.org/experiments/rtp-hdrext/abs-send-time\r\n\
a=sendrecv\r\n\
a=rtcp-mux\r\n\
a=rtpmap:111 opus/48000/2\r\n\
a=rtcp-fb:111 transport-cc\r\n\
a=fmtp:111 minptime=10;useinbandfec=1\r\n\
a=rtpmap:103 ISAC/16000\r\n\
a=rtpmap:9 G722/8000\r\n\
a=rtpmap:102 ILBC/8000\r\n\
a=rtpmap:0 PCMU/8000\r\n\
a=rtpmap:8 PCMA/8000\r\n\
a=rtpmap:105 CN/16000\r\n\
a=rtpmap:13 CN/8000\r\n\
a=rtpmap:126 telephone-event/8000\r\n\
a=ssrc:2389753583 cname:SBlo7Qp9L+Cjypud\r\n\
a=ssrc:2389753583 msid:e9816adf-013c-4532-a144-d5b87e433c1d b22ead35-895c-49ad-ad5f-685de077194f\r\n\
a=ssrc:2389753583 mslabel:e9816adf-013c-4532-a144-d5b87e433c1d\r\n\
a=ssrc:2389753583 label:b22ead35-895c-49ad-ad5f-685de077194f\r\n\
";
*/
