import I18n from "i18n-js";
I18n.defaultLocale = "en"; // --- set fallbacks default language
I18n.fallbacks = true; // --- enable fallback
I18n.locale = window.navigator.language; // --- decide user's locale

// --- translations
I18n.translations = {};
I18n.translations["en"] = {
	AHOY: "AHOY",
	Reload: "Reload",
	ExtraNetworkCall: 'Extra Network Call',
	AhoyCall: 'AHOY Free Call',
	Talking: 'Talking',
	CallConnecting: 'Call Connecting',
	Calling_from: 'Calling from',
	Call_processing: 'Processing...',
	CallEnding: 'Call Ending',
	NetworkUnavailable: 'Network Unavailable',
	PleaseCheckNetworkDesc: 'Please check your network is stable then try again',
	IncorrectWebCallData: 'Incorrect WebCall Data',
	WebWarning: 'DO NOT turn off your screen or hide the browser to background',
}

I18n.translations["zh"] = {
	AHOY: "AHOY",
	Reload: "重新整理",
	ExtraNetworkCall: '網外通話',
	AhoyCall: 'AHOY 免費通話',
	Talking: '通話中',
	CallConnecting: '接通中',
	Calling_from: '通話來自',
	Call_processing: '處理中',
	CallEnding: '通話結束中',
	NetworkUnavailable: '無法連接上網',
	PleaseCheckNetworkDesc: '請確認網路是否穩定, 再試一次',
	IncorrectWebCallData: 'WebCall 資料不正確',
	WebWarning: 'WebCall 通話中請勿關閉螢幕或將瀏覽器縮小至背景',
}

export default I18n;
