import React from 'react'
import Layout from './components/Layout.js'
import WindowSize from './components/WindowSize.js'
import util from 'util'
import * as LIB from './lib/lib.js';
import AppSetup from './app_setup.js';
import autobind from 'autobind-decorator';
import * as SIP from 'sip.js';
import I18n from './lib/i18n.js';
import Talk from './Talk.js'
import {
	Text,
	View,
	StyleSheet,
	Platform,
} from 'react-native-web';
//import MaterialIcon from 'react-native-vector-icons/dist/MaterialIcons';
import { 
	Button as ButtonElement,
} from 'react-native-elements';
import { MaterialTheme } from './lib/styles.js';
import axios from 'axios';
import Modal from 'react-modal';
import SDPTransform from 'sdp-transform'; // --- sdp parser and writer
import * as LibSDP from './lib/sdp.js'; // --- sdp manipulate functions

const xlog = require('./lib/xlog.js'); // --- use reqiure so that we can use `pure_funcs` to strip in production build

const Theme = MaterialTheme[AppSetup.Theme];
var DetectRTC;

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root')

//import fetch from 'isomorphic-unfetch'
@autobind
class App extends React.Component {
	constructor(props) {
		super(props);
		xlog.info('[App] constructor()');

		this.remoteAudio = null;
		this.session = null;
		this.ua = null;
		this.shouldRegisterAfterConnect = false;
		this.sdp = false;

		this.state = {
			call_session_active: false,
			call_session_callid: '',
			call_session_event: 'terminated',
			session_call_start_time: 0,

			localMediaStream: null,
			remoteMediaStream: null,
			RENDER: '',
			DetectRTC: null,
			appInitialized: false, // --- mainly means: api to get webcall info has finished or not.
			webcallInfo: {
				calleeNumber: '',
				calleeName : '',
				caller: '',
				callerPwd : '',
			},
			modal: {
				isModalOpen: false,
				view: null,
			},
			talkingCallInfo: {},
		};
	}

	componentDidCatch(error, info) {
		xlog.info('App: componentDidCatch, error = ', error);
		xlog.info('App: componentDidCatch, info = ', info);
	}

	async triggerRender(randomValue) {
		if (!randomValue) {
			// --- default use timestamp is enough to trigger re-render
			// --- the side-benifit is we can prevent simutanous calls to re-render since the ms might be the same
			randomValue = Date.now().toString();
		}
		this.setState({RENDER: randomValue});
	}

	async onDetectRTCLoad() {
		xlog.info('[App] onDetectRTCLoad() DetectRTC = ', DetectRTC);
		this.triggerRender();
		await this.setState({DetectRTC});
		/*
		DetectRTC.hasWebcam; // (has webcam device!)
		DetectRTC.hasMicrophone; // (has microphone device!)
		DetectRTC.hasSpeakers; // (has speakers!)
		DetectRTC.isScreenCapturingSupported; // Chrome, Firefox, Opera, Edge and Android
		DetectRTC.isSctpDataChannelsSupported;
		DetectRTC.isRtpDataChannelsSupported;
		DetectRTC.isAudioContextSupported;
		DetectRTC.isWebRTCSupported;
		DetectRTC.isDesktopCapturingSupported;
		DetectRTC.isMobileDevice;

		DetectRTC.isWebSocketsSupported;
		DetectRTC.isWebSocketsBlocked;
		DetectRTC.checkWebSocketsSupport(callback);

		DetectRTC.isWebsiteHasWebcamPermissions;		// getUserMedia allowed for HTTPs domain in Chrome?
		DetectRTC.isWebsiteHasMicrophonePermissions;	// getUserMedia allowed for HTTPs domain in Chrome?

		DetectRTC.audioInputDevices;	// microphones
		DetectRTC.audioOutputDevices;   // speakers
		DetectRTC.videoInputDevices;	// cameras

		DetectRTC.osName;
		DetectRTC.osVersion;

		DetectRTC.browser.name === 'Edge' || 'Chrome' || 'Firefox';
		DetectRTC.browser.version;
		DetectRTC.browser.isChrome;
		DetectRTC.browser.isFirefox;
		DetectRTC.browser.isOpera;
		DetectRTC.browser.isIE;
		DetectRTC.browser.isSafari;
		DetectRTC.browser.isEdge;

		DetectRTC.browser.isPrivateBrowsing; // incognito or private modes

		DetectRTC.isCanvasSupportsStreamCapturing;
		DetectRTC.isVideoSupportsStreamCapturing;

		DetectRTC.DetectLocalIPAddress(callback);
		*/
	};

	detectRTCCheckAvailability() {
		// --- will display message when:
		// ---  1. really unavailable OR 2. debug server
		let unsupportedMsg = '';
		let available = true;

		if (!this.state.DetectRTC) {
			unsupportedMsg = '偵測程序中或有誤，可能需要重新整理。';
			available = true;
			return { available, unsupportedMsg };
		}

		if (!this.state.DetectRTC.isWebRTCSupported) {
			unsupportedMsg = '您的瀏覽器不支援語音通訊。建議使用 Chrome 最新版。';
			available = false;
			return { available, unsupportedMsg };
		} else if (!this.state.DetectRTC.isWebSocketsSupported || this.state.DetectRTC.isWebSocketsBlocked) {
			unsupportedMsg = '您的瀏覽器不支援 WebSocket 連線。';
			available = false;
			return { available, unsupportedMsg };
		} else if (this.state.DetectRTC.osName === 'iOS' && !this.state.DetectRTC.browser.isSafari) {
			unsupportedMsg = 'iPhone 目前僅支援 Safari\n請使用 Safari 瀏覽器通話';
			available = false;
			return { available, unsupportedMsg };
		}
		
		// --- do not rely on microphone/speaker detection because it may needs permissions. and is not 100% accurate across browsers.
		/*
		// --- mobile always has microphone and speaker phone,
		// --- some device especially ios will throw false-negative warning
		// --- that conplains microphone/speaker not found.
		//if (this.state.DetectRTC.isMobileDevice && this.state.DetectRTC.osName === 'iOS') {
		if (!this.state.DetectRTC.hasMicrophone) {
			unsupportedMsg = '偵測不到麥克風，請使用支援麥克風的裝置。';					
			available = (this.state.DetectRTC.isMobileDevice ? true : false); 
			return { available, unsupportedMsg };
		} else if (!this.state.DetectRTC.hasSpeakers) {
			unsupportedMsg = '偵測不到音訊播放，請使用支援音訊播放的裝置。';					
			available = (this.state.DetectRTC.isMobileDevice ? true : false); 
			return { available, unsupportedMsg };
		}
		//} else if (!this.state.DetectRTC.isWebsiteHasMicrophonePermissions) {
		//	unsupportedMsg = '需要麥克風權限';
		//} else if (!this.state.DetectRTC.isPromisesSupported) {
		//	unsupportedMsg = '您的瀏覽器版本太舊';
		//}
		*/

		available = true;
		return { available, unsupportedMsg };
	}

	async getWebCallInfo(callHash) {
		let webcallInfo = {
			calleeNumber: '',
			calleeName : '',
			caller: '',
			callerPwd : '',
		}
		if (!callHash) {
			// --- no op
		} else {
			// --- not hardcoded one, query api
			try {
				// --- keep in mind the CORS issues
				let res = await axios({
					method: 'POST',
					url: 'https://' + AppSetup.landingServerDomain + '/api/getWebCallData',
					data: {
						WebCallToken: callHash,
						key: AppSetup.landingServerClientKey, // --- this is required for landing server which simply checks key to prevent randomly, automatically sniffing attacks
					},
					// --- if you want to use www-form-urlencoded
					//headers: {
					//  'content-type': 'application/x-www-form-urlencoded'
					//},
					//data: queryString.stringify({
				});

				let resData = res.data;

				if (!resData) {
					xlog.info('[ERROR][API] res is empty');
				} else if (resData.errorCode) {
					xlog.info('[ERROR][API] res has errorCode: ' + resData.errorCode);
				} else {
					webcallInfo.calleeNumber = resData.data.WebCallData.callee;
					webcallInfo.calleeName = resData.data.WebCallData.callee_description;
					webcallInfo.caller = resData.data.WebCallData.caller;
					webcallInfo.callerPwd = resData.data.WebCallData.caller_pwd;
				}
			} catch (err) {
				xlog.info('[ERROR][API] exception: ' + err);
			}
		}

		return webcallInfo;
	}

	componentDidMount() {
		xlog.info('[App] componentDidMount() Enter');

		this.getWebCallInfo(this.props.query.callHash)
		.then((webcallInfo) => {
			let _webcallInfo = { ...webcallInfo };
			if (_webcallInfo.callerPwd) {
				_webcallInfo.callerPwd = '*******************';
			}
			xlog.info('[App] componentDidMount() async getWebCallInfo(). webcallInfo = ', _webcallInfo);
			this.setState({ webcallInfo, appInitialized: true });
		})
		.catch((err) => {
			xlog.info('[App] componentDidMount() async getWebCallInfo() FAILED. err = ', err);
			this.setState({ appInitialized: true });
		});

		// --- start etect availability on client
		this.detectRTCProcess()
		.then(this.onDetectRTCLoad)
		.catch((err) => {
			xlog.info('[App] componentDidMount() async detectRTCProcess() FAILED. DetectRTC failed. err = ', err);
		})
		.then(() => {
			let { available, unsupportedMsg } = this.detectRTCCheckAvailability();
			xlog.info('[App] componentDidMount() async detectRTCCheckAvailability(). available = %s, unsupportedMsg = %s', available, unsupportedMsg);
		})

		xlog.info('[App] componentDidMount() Leave');
	}

	reactivateAudioForIosSafariWorkaround() {
		//if (DetectRTC.isMobile.iOS() && this.remoteAudio) {
		if (DetectRTC.osName === 'iOS' && this.remoteAudio) {
			xlog.info('[App] reactivateAudioForIosSafariWorkaround() workaround for ios');
			this.remoteAudio.muted = true;
			this.remoteAudio.muted = false;
		}
	}

	detectRTCProcess() {
		return new Promise((resolve, reject) => {
			if (!DetectRTC) {
				DetectRTC = require('detectrtc');
			}
			try {
				DetectRTC.load(resolve);
			} catch (err) {
				reject(err);
			}
		});
	}

	closeSipUaInstance() {
		//this.dispatch(Actions.UPDATE_IS_SIP_UA_CLOSING(true));
		if (this.ua) {
			xlog.info("[Cue]: closeSipUaInstance()");
			try {
				this.ua.stop();
			} catch (e) {
				//xlog.info('[ERROR][closeSipUaInstance] err =', e.stack);
			}
			delete this.ua;
			this.ua = false;
		}
		//this.dispatch(Actions.UPDATE_IS_SIP_UA_CLOSING(false));
		//this.dispatch(Actions.UPDATE_REGISTER_STATUS(false));
	}


	createSipUaInstance(setup) {
		setup = (typeof setup === 'undefined') ? {} : setup;

		try {
			this.closeSipUaInstance();
			xlog.info("[Cue][createSipUaInstance]");
			this.ua = new SIP.UA(this.getSipUaInitSettings(setup)); // --- customServerIpPort: string, rel100: boolean
			this.setSipUaEvents(setup); // --- moveTaskToBackAfterEnd
		} catch (err) {
			this.ua = false;
			xlog.info("[Cue][createSipUaInstance] this.ua creation failed. catch error=%s", err);
		}
	}

	logSipJSConnector(level, category, label, content) {
		// --- level: String representing the level of the log message: ('debug', 'log', 'warn', 'error')
		// --- category: String representing the SIPjs instance class firing the log. ie: 'sipjs.ua'
		// --- label: String indicating the 'identifier' of the class instanc when the log level is '3' (debug). ie: transaction.id
		// --- content: String representing the log message

		// --- label is some trasaction tags. it's too verbose for us now.
		let msg = `[SipJS][${category}]: ${content}`;
		xlog.info(msg);
	}

	// --- param: customServerIpPort: string, rel100: boolean
	getSipUaInitSettings(setup) {
		setup = (typeof setup === 'undefined') ? {} : setup;
		let customServerIpPort = (typeof setup.customServerIpPort === 'string') ? setup.customServerIpPort : '';
		let rel100 = (setup.rel100 === true) ? true : false;

		let sipUaInfo = {
			uri: '',
			authorizationUser: '',
			password: '',
			userAgentString: "AHOY_WebCall",
			noAnswerTimeout: 40, // --- default 60, should match with max rintone/vibration length
			log: {
				builtinEnabled: false,
				level: 3,
				connector: this.logSipJSConnector, // move to cue.js
			}, // --- 3, 2, 1, 0 or "debug", "log", "warn", "error"; default 2
			register: false,
			autostart: false,
			registerOptions: {
				expires: 600,
			},
			transportOptions: {
				wsServers: '',
				connectionTimeout: 5, // --- default 5
				maxReconnectionAttempts: 3, // --- default 3
				reconnectionTimeout: 4, // --- default 4
				//keepAliveInterval: 0, // --- default 0
				keepAliveInterval: 10, // --- default 0
				//traceSip: (process.env.NODE_ENV === 'development'),
				traceSip: (typeof AppSetup.logToConsole === 'boolean' ? AppSetup.logToConsole : false),
			},
			sessionDescriptionHandlerFactoryOptions: this.getSessionDescriptionHandlerOptions({isUaInit: true}),
		}; // --- use new object

		let serverIpPort = '';
		let serverProtocol = '';

		// --- if we are callee, we should register to where server told us. ignore all server list settings.
		let t = customServerIpPort.split(':');
		if (t.length > 2) {
			// --- contains specified protocol, ex: ws://x.x.x.x:5003
			serverProtocol = t[0];
			serverIpPort = customServerIpPort.substring(serverProtocol.length + 3); // --- 3 is the length of '://'
		} else {
			// --- not contain specified protocol, ex: x.x.x.x:5003
			serverIpPort = customServerIpPort;
			// --- workaround: choose protocol based on port, old client doesn't support customServerIpPort parsing.
			serverProtocol = (t[t.length - 1] === '5003' ? 'ws' : 'wss');
		}

		let username = '';
		let password = '';
		// --- for debug
		if (AppSetup.isDebug && this.props.query.queryString.customCallerUsername && this.props.query.queryString.customCallerPassword) {
			username = this.props.query.queryString.customCallerUsername;
			password = this.props.query.queryString.customCallerPassword;
			xlog.info('[Cue][getSipUaInitSettings] !!! use custom username = %s, password = %s', username, '*'.repeat(password.length));
		} else if (this.state.webcallInfo.caller && this.state.webcallInfo.callerPwd) {
			username = this.state.webcallInfo.caller;
			password = this.state.webcallInfo.callerPwd;
		}

		sipUaInfo.uri = username + '@' + serverIpPort;
		sipUaInfo.authorizationUser = username;
		sipUaInfo.password = password;

		sipUaInfo.transportOptions.wsServers = [serverProtocol+ '://' + serverIpPort];
		// --- only outgoing call should add this. 
		// --- since app doesn't have early media to play to remote party
		if (rel100 === true) {
			//sipUaInfo.rel100 = SIP.C.supported.SUPPORTED;
			sipUaInfo.rel100 = SIP.C.supported.REQUIRED;
		} else {
			sipUaInfo.rel100 = SIP.C.supported.UNSUPPORTED;
		}
		return sipUaInfo;
	}

	getSipAppendExtraHeaders({headers = {}, returnObj = false} = {}) {
		if (!headers) headers = {};
		let extraHeadersObj = {
			'X-RTP-ENCRYPT-METHOD': '2',
			'X-WEBCALL-HASH': (this.props.query.callHash ? this.props.query.callHash : ''),
			...headers,
		}

		if (returnObj === true) {
			return extraHeadersObj;
		} else {
			let extraHeadersArray = [];

			Object.keys(extraHeadersObj).forEach((k) => {
				extraHeadersArray.push(k + ': ' + extraHeadersObj[k]);
			});

			// --- sip.js accept only array
			return extraHeadersArray;
		}
	}

	setSipUaEvents(setup) {
		if (!this.ua) {
			xlog.info("[Cue][setSipUaEvents] this.ua is empty");
			return false;
		}

		setup = (typeof setup === 'undefined') ? {} : setup;
		let sipExtraHeaderRegister = setup.sipExtraHeaderRegister;

		this.ua.on('connecting', (args) => {
			xlog.info('[SIPUA_EVENT][connecting] attempts: ', args.attempts);
		});

		this.ua.on('connected', () => {
			xlog.info('[SIPUA_EVENT][connected]');
			if (this.shouldRegisterAfterConnect && !this.ua.isRegistered()) {
				// --- if we use push wake up, it means we don't have persistent connections.
				this.ua.register({
					extraHeaders: this.getSipAppendExtraHeaders({headers: sipExtraHeaderRegister, returnObj: false}),
				});
			}
			this.shouldRegisterAfterConnect = false;
		});

		this.ua.on('disconnected', () => {
			xlog.info('[SIPUA_EVENT][disconnected]');
			//this.dispatch(Actions.UPDATE_REGISTER_STATUS(false));
		});

		this.ua.on('registered', () => {
			xlog.info('[SIPUA_EVENT][registered]');
			//this.dispatch(Actions.UPDATE_REGISTER_STATUS(true));
		});

		this.ua.on('unregistered', (cause) => {
			let cause_text = cause ? cause : '';
			xlog.info('[SIPUA_EVENT][unregistered], cause: ', cause_text);
			//this.dispatch(Actions.UPDATE_REGISTER_STATUS(false));
		});

		this.ua.on('registrationFailed', (cause) => {
			let cause_text = cause && typeof cause === 'object' ? cause.status_code + ' - ' + cause.reason_phrase : 'register failed';
			xlog.info('[SIPUA_EVENT][registrationFailed], cause: ', cause_text);
			// --- handle push wakeup failed
			//this.dispatch(Actions.UPDATE_REGISTER_STATUS(false));

			if (cause && typeof cause === 'object' &&
				(cause.status_code === 401 || cause.status_code === 407)) {
				xlog.info("[Cue][SIPUA_EVENT][registrationFailed] Authentication Error");
			}
		});

		this.ua.on('invite', async (session) => {
			xlog.info('[SIPUA_EVENT][invite]');
		});

		this.ua.on('message', (message) => {
			// --- no need to check callid because "MESSAGE" is a independent transaction to "INVITE"
			// --- will dispatch to redux and Talk will render related info based on outgoing / incoming
			xlog.info('[SIPUA_EVENT][message] received message: ', message.body);

			let callInfo = this.parseCallInfoJson(message.body); // --- should return null or Object
			if (!callInfo) {
				return; // --- parse json failed.
			}

			if (Object.keys(callInfo).length > 0) {
				this.updateTalkingCallInfoPartial(callInfo);
			}
		});
	}


	setCallSessionEvents(callid) {
		//var shouldPlayBusyTone = false;
		/*
		* --- methods in session object would throw:
		* - dtmf()
		* - refer()
		* - hold()
		* - unhold()
		* - reject()
		* - accept()
		* - cancel()
		* - InviteClientContext constructor
		*/

		this.session.on('trackAdded', () => {
			// --- We need to check the peer connection to determine which track was added
			xlog.info("[SIPSESSION_EVENT][trackAdded][%s] session.on('trackAdded')", callid);

			var pc = this.session.sessionDescriptionHandler.peerConnection;

			// Gets remote tracks
			var remoteStream = new MediaStream();
			pc.getReceivers().forEach(function(receiver) {
				remoteStream.addTrack(receiver.track);
			});

			xlog.info("[SIPSESSION_EVENT][trackAdded][%s] session.on('trackAdded') remoteStream = ", callid, remoteStream);
			this.setState({remoteMediaStream: remoteStream}, () => {
				xlog.info('remoteMediaStream setState finished');
				this.remoteAudio.srcObject = remoteStream;
			});

			// Gets local tracks
			/*
			var localStream = new MediaStream();
			pc.getSenders().forEach(function(sender) {
				localStream.addTrack(sender.track);
			});
			localVideo.srcObject = localStream;
			localVideo.play();
			*/
		});

		this.session.on('SessionDescriptionHandler-created', (sessionDescriptionHandler) => {
			this.sessionDescriptionHandler = sessionDescriptionHandler;

			this.sessionDescriptionHandler.on('userMediaRequest', (constraints) => {
				// --- object, The constraints that were used with getUserMedia().
				xlog.info("[SIPSESSION_EVENT][userMediaRequest][%s] session.on('userMediaRequest') constraints = %j", callid, constraints);
			});

			this.sessionDescriptionHandler.on('userMediaFailed', (error) => {
				// --- string, The message returned from the getUserMedia failure.
				xlog.info("[SIPSESSION_EVENT][userMediaFailed][%s] session.on('userMediaFailed') error =  ", error);
			});

			this.sessionDescriptionHandler.on('userMedia', (stream) => {
				// --- Fired when getUserMedia() returned local media.

				if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
					xlog.info('[SIPSESSION_EVENT][userMedia][%s] current call_session_active is false. return.', callid);
					stream.release();
					return;
				}
				if (callid !== this.state.call_session_callid) {
					xlog.info('[SIPSESSION_EVENT][userMedia][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
					stream.release();
					return;
				}
				xlog.info('[SIPSESSION_EVENT][userMedia][%s] session on userMedia. (local) stream= ', callid, stream);

				/*
				// --- see: handleAnswerCall() muteCamera. we should mute it at the very beginning
				if (this.muteCameraForLocalStream) {
					xlog.info("[Cue][userMedia] mute localStream at the beginning due to this.muteCameraForLocalStream is true. ( answer without camera )");
					let tracks = stream.getVideoTracks();
					if (tracks.length > 0) {
						tracks.forEach((element, index, array) => {
							element.enabled = false; // --- this also mute
						})
					}
				}
				*/

				stream.isLocal = true;
				this.setState({localMediaStream: stream});
			});

			/*
			// --- this occurs before callee received `invite` event, so `remoteStream` would never fired. see: ua.on('invite')
			this.sessionDescriptionHandler.on('addStream', (e) => {
				// --- Fired when a new stream is added to the PeerConnection.
				// --- Deprecated. Note: This has been deprecated in the WebRTC api for the new addTrack event instead. Neither api is currently fully supported in all browser enviornments.
				if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
					xlog.info('[SIPSESSION_EVENT][addStream][%s] current call_session_active is false. return.', callid);
					return;
				}
				if (callid !== this.state.call_session_callid) {
					xlog.info('[SIPSESSION_EVENT][addStream][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
					return;
				}
				xlog.info('[SIPSESSION_EVENT][addStream][%s] session on addStream. (remote) stream: ', callid, e.stream);
				//this.setState({remoteMediaStream: e.stream});
				//this.onAddStream();
				e.stream.isLocal = false;
				this.setState({remoteMediaStream: e.stream}, () => {
					this.remoteAudio.srcObject = e.stream;
					this.remoteAudio.play().catch(() => {
						xlog.info("ZXCPOIU play was rejected");
					});
				});
			});
			*/

			/*
			// --- if this event exist, addStream will not fired
			this.sessionDescriptionHandler.on('addTrack', (track) => {
				// --- Fired when a new track is added to the PeerConnection.
				xlog.info("[SIPSESSION_EVENT][addTrack][%s] session.on('addTrack'), track = ", callid, track);
					//this.remoteAudio.srcObject = remoteStream;
					//this.remoteAudio.play().catch(() => {
					//	xlog.info("ZXCPOIU play was rejected");
					//});
			});
			*/

			this.sessionDescriptionHandler.on('iceGathering', () => {
				// --- Fired when the WebRTC layer has started gathering ICE candidates.
				xlog.info("[SIPSESSION_EVENT][iceGathering][%s] session.on('iceGathering') ", callid);
			});

			this.sessionDescriptionHandler.on('iceCandidate', (candidate) => {
				// --- Fired each time the WebRTC layer finds an ICE candidate.
				xlog.info("[SIPSESSION_EVENT][iceCandidate][%s] session.on('iceCandidate'), candidate = %s ", callid, candidate);
			});

			this.sessionDescriptionHandler.on('iceGatheringComplete', () => {
				// --- Fired when the WebRTC layer has finished gathering ICE candidates.
				xlog.info("[SIPSESSION_EVENT][iceGatheringComplete][%s] session.on('iceGatheringComplete') ", callid);
			});

			this.sessionDescriptionHandler.on('iceConnection', () => {
				// --- Fired when the ICE connection state is new.
				xlog.info("[SIPSESSION_EVENT][iceConnection][%s] session.on('iceConnection') ", callid);
			});

			this.sessionDescriptionHandler.on('iceConnectionChecking', () => {
				// --- Fired when the ICE connection state is checking.
				xlog.info("[SIPSESSION_EVENT][iceConnectionChecking][%s] session.on('iceConnectionChecking') ", callid);
			});

			this.sessionDescriptionHandler.on('iceConnectionConnected', () => {
				// --- Fired when the ICE connection state is connected.
				xlog.info("[SIPSESSION_EVENT][iceConnectionConnected][%s] session.on('iceConnectionConnected') ", callid);
			});

			this.sessionDescriptionHandler.on('iceConnectionCompleted', () => {
				// --- Fired when the ICE connection state is completed.
				xlog.info("[SIPSESSION_EVENT][iceConnectionCompleted][%s] session.on('iceConnectionCompleted') ", callid);
			});

			this.sessionDescriptionHandler.on('iceConnectionFailed', () => {
				// --- Fired when the ICE connection state is failed.
				xlog.info("[SIPSESSION_EVENT][iceConnectionFailed][%s] session.on('iceConnectionFailed') ", callid);
			});

			this.sessionDescriptionHandler.on('iceConnectionDisconnected', () => {
				// --- Fired when the ICE connection state is disconnected.
				xlog.info("[SIPSESSION_EVENT][iceConnectionDisconnected][%s] session.on('iceConnectionDisconnected') ", callid);
			});

			this.sessionDescriptionHandler.on('iceConnectionClosed', () => {
				// --- Fired when the ICE connection state is closed.
				xlog.info("[SIPSESSION_EVENT][iceConnectionClosed][%s] session.on('iceConnectionClosed') ", callid);
			});

			this.sessionDescriptionHandler.on('getDescription', (sdpWrapper) => {
				// --- sdpWrapper = {type: 'offer/answer', sdp: 'sdp string'}
				xlog.info("[SIPSESSION_EVENT][getDescription][%s] session.on('getDescription'), sdpWrapper = %j ", callid, sdpWrapper);
			});

			this.sessionDescriptionHandler.on('setDescription', (sdpWrapper) => {
				// --- sdpWrapper = {type: 'offer/answer', sdp: 'sdp string'}
				xlog.info("[SIPSESSION_EVENT][setDescription][%s] session.on('setDescription'), sdpWrapper = %j ", callid, sdpWrapper);
			});

		});

		this.session.on('progress', (response, cause) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][progress][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][progress][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			let cause_text = cause ? cause : '';
			let status_code = (response && response.status_code ? response.status_code : '');
			let reason_phrase = (response && response.reason_phrase ? response.reason_phrase : '');
			xlog.info('[SIPSESSION_EVENT][progress][%s] session progress. status_code=%s, reason_phrase=%s, cause=%s', callid, status_code, reason_phrase, cause_text);

			this.setState({call_session_event: 'progress'});

			if (this.state.call_session_active === 1) {
				// --- outgoing
				
				// --- if it's 180 or 183, and it does contain sdp, stop fake local rinback and use early media
				if (response.status_code === 183 || response.status_code === 180) {
					if (response.body) {
						try {
							let sdpObj = SDPTransform.parse(response.body);
							if (sdpObj.media && Array.isArray(sdpObj.media) && sdpObj.media.length > 0) {
								xlog.info('[SIPUA_EVENT][progress] received %s and sdp does contain media m line. stop local ringback and use early media as remote ringback', response.status_code);
								//InCallManager.stopRingback();
							} else {
								xlog.info('[SIPUA_EVENT][progress] received %s and sdp does NOT contain media m line. keep playing local ringback', response.status_code);
							}
						} catch (e) {
							xlog.info('[SIPUA_EVENT][progress] received %s and has sdp but parsing failed. assumed it contains early media, so stop local ringback', response.status_code);
							//InCallManager.stopRingback();
						}
					} else {
						xlog.info('[SIPUA_EVENT][progress] received %s and does NOT contain sdp body. keep playing local ringback', response.status_code);
					}
				} else {
					xlog.info('[SIPUA_EVENT][progress] received %s response', response.status_code);
				}
			} else {
				// --- incoming
			}
		});

		this.session.on('accepted', (response, cause) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][accepted][%s] current call_session_active is false. return.', callid);
				return;
			} else if (this.state.call_session_active === 1) {
				// --- outgoing
				//InCallManager.stopRingback();
				//RNCallKit.reportConnectedOutgoingCallWithUUID(this.currentCalls[callid].uuid);
			} else if (this.state.call_session_active === 2) {
				// --- incoming
				//InCallManager.stopRingtone(); // --- handled in handleAnswerCall() and handleRejectCall()
				//if (!LIB.useCallKit(this.state.Settings.useNotificationForIncomingCall)) {
				//	InCallManager.start({media: (this.state.globalStatus.shouldUseVideo ? 'video' : 'audio'), ringback: false});
				//}
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][accepted][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			let cause_text = cause ? cause : '';
			let status_code = (response && response.status_code ? response.status_code : '');
			let reason_phrase = (response && response.reason_phrase ? response.reason_phrase : '');
			xlog.info('[SIPSESSION_EVENT][accepted][%s] session accepted. status_code=%s, reason_phrase=%s, cause=%s', callid, status_code, reason_phrase, cause_text);

			//AhoyReactor.notifyCallEventListener(Constants.AHOY_CALL_EVENTS.ACCEPTED);

			this.setState({call_session_event: 'answered'}, () => {
				xlog.info('answered: remoteStream = ', this.state.remoteMediaStream);
				this.remoteAudio.srcObject = this.state.remoteMediaStream;
				this.remoteAudio.play()
					.then(() => {
						xlog.info("audio.play() succeed.");
					})
					.catch((err) => {
						// --- ZXCPOIU !!!! on safari, should show alert to let user starts play audios, or just play a audio sound before call, and let user checks
						xlog.info("audio.play() rejected, err = ", err);
					});
			});

			if (this.session.startTime) {
				this.setState({session_call_start_time: Math.round(this.session.startTime.getTime() / 1000)});
			}

			// --- dismiss Talking control panel when answered. failure-free
			//this.dismissTalkControlPanel();
		});

		this.session.on('rejected', (response, cause) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][rejected][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][rejected][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			let cause_text = cause ? cause : '';
			let status_code = (response && response.status_code ? response.status_code : '');
			let reason_phrase = (response && response.reason_phrase ? response.reason_phrase : '');
			xlog.info('[SIPSESSION_EVENT][rejected][%s] session rejected. status_code=%s, reason_phrase=%s, cause=%s', callid, status_code, reason_phrase, cause_text);
		});

		this.session.on('failed', (response, cause) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][failed][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][failed][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			let cause_text = cause ? cause : '';
			let status_code = (response && response.status_code ? response.status_code : '');
			let reason_phrase = (response && response.reason_phrase ? response.reason_phrase : '');
			xlog.info('[SIPSESSION_EVENT][failed][%s] session failed. status_code=%s, reason_phrase=%s, cause=%s', callid, status_code, reason_phrase, cause_text);
		});

		this.session.on('terminated', (response, cause) => {
			let cause_text = cause ? cause : '';
			let status_code = (response && response.status_code ? response.status_code : '');
			let reason_phrase = (response && response.reason_phrase ? response.reason_phrase : '');
 
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][terminated][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][terminated][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			xlog.info('[SIPSESSION_EVENT][terminated][%s] session terminated. status_code=%s, reason_phrase=%s, cause=%s', callid, status_code, reason_phrase, cause_text);
			this.setState({call_session_event: 'terminated'});
			this.destroyCallSession();
		});

		this.session.on('cancel', () => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][cancel][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][cancel][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			xlog.info('[SIPSESSION_EVENT][cancel][%s] session cancel.', callid);
			this.setState({call_session_event: 'terminated'});
		});

		//this.session.on('refer', session.followRefer(callback)); // --- follow refer directlly
		this.session.on('refer', (request) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][refer][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][refer][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			xlog.info('[SIPSESSION_EVENT][refer][%s] session refer.', callid);
			//dispatch(Actions.UPDATE_CALL_SESSION_EVENT('refer'))
		});

		this.session.on('replaced', (newSession) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][replaced][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][replaced][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			xlog.info('[SIPSESSION_EVENT][replaced][%s] session replaced.', callid);
			//dispatch(Actions.UPDATE_CALL_SESSION_EVENT('replaced'))
		});

		this.session.on('dtmf', (request, dtmf) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][dtmf][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][dtmf][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			xlog.info('[SIPSESSION_EVENT][dtmf][%s] session dtmf.', callid);
			//dispatch(Actions.UPDATE_CALL_SESSION_EVENT('dtmf'))
		});

		this.session.on('muted', (data) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][muted][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][muted][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			xlog.info('[SIPSESSION_EVENT][muted][%s] session muted.', callid);
			//dispatch(Actions.UPDATE_CALL_SESSION_EVENT('muted'))
		});

		this.session.on('unmuted', (data) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][unmuted][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][unmuted][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			xlog.info('[SIPSESSION_EVENT][unmuted][%s] session unmuted.', callid);
			//dispatch(Actions.UPDATE_CALL_SESSION_EVENT('unmuted'))
		});

		this.session.on('bye', (request) => {
			if (this.state.call_session_active === false || this.state.call_session_event === 'terminated') {
				xlog.info('[SIPSESSION_EVENT][bye][%s] current call_session_active is false. return.', callid);
				return;
			}
			if (callid !== this.state.call_session_callid) {
				xlog.info('[SIPSESSION_EVENT][bye][%s] mismatched callid. current: %s', callid, this.state.call_session_callid);
				return;
			}
			xlog.info('[SIPSESSION_EVENT][bye][%s] session bye.', callid);
		});
	}


	// --- return Object if succeed, return null if failed
	parseCallInfoJson(callInfoJsonStr) {
		let parsed;
		if (!callInfoJsonStr) {
			return;
		}
		try {
			parsed = JSON.parse(callInfoJsonStr);
		} catch (e) {
			xlog.info('[ERROR][SIPUA_EVENT][message] parseCallInfoJson() failed. JSON parse error, e: %s', e.message);
			return;
		}

		if (!LIB.isObject(parsed)) {
			xlog.info('[ERROR][SIPUA_EVENT][message] parseCallInfoJson() failed. parsed result is not an object');
			return;
		}

		// --- only update columns we recognized
		let callInfo = {};

		[
			// --- General
			'maxDuration',
			'callerUsername',
			'calleeUsername',
			'callerIsAhoy',
			'calleeIsAhoy',

			// --- Outgoing (for Caller to know)
			'calleeType', // --- "0: onnet, 1: pstn, 2: other sip server, 3: service (e.g. 599)
			
			// --- Open Door
			'supportOpenDoor', // --- '0' / '1'
			'callerApartmentDeviceType',
			'calleeApartmentDeviceType',
			'callerDeviceHardware',
			'calleeDeviceHardware',

			// --- Incoming ( for Callee to know )
			'fromhostBridgeNumber',
			'fromhostServiceType',
			'fromhostUserNumber',
			'onnetMatchedPstnReal', // --- string pstn number if onnet matched
			'isSimulRing', // --- '0' / '1'
			'isVpbxRing', // --- '0' / '1'
			'vpbxUserPressed', // --- string, ext number or 'all'
		].forEach((item) => {
			if (item in parsed) {
				callInfo[item] = parsed[item];
			}
		});

		return callInfo;
	}

	handleDTMF(dtmf) {
		xlog.info('handleDTMF(): %s', dtmf);
		if (this.state.call_session_active !== false
				&& this.session
				&& (this.session.status === SIP.Session.C.STATUS_CONFIRMED || this.session.status === SIP.Session.C.STATUS_WAITING_FOR_ACK)
				&& dtmf.match(/^[0-9A-D#*,]+$/i)) {
			this.session.dtmf(dtmf);
		} else {
			xlog.info('handleDTMF() %s, no session to send', dtmf);
		}

		/*
		// --- enable this if we want play dtmf tone when dialpad pressed.
		var dtmfTone;
		if (dtmf === '*') {
			dtmf = 'star';
		} else if (dtmf === '#' || dtmf === '+') {
			dtmf = 'pound';
		}
		dtmfTone = this.dtmfTone[dtmf] || null;
		if (dtmfTone) {
			// Play the sound with an onEnd callback
			dtmfTone.play((success) => { xlog.info(`play dtmf ${dtmf} success: ` + success ? 'true' : 'false') ; });
			setTimeout(() => {
					dtmfTone.stop();
			}, 50);
		} else {
			xlog.info('dtmf tone not found: ', dtmf);
		}
		*/
	}

	async handleMakeCall({calleeName = '', calleeNumber = '', preferVideo = false, extenCallGroup = '', callerApartmentInfo = ''} = {}) {
		xlog.info('[App] handleMakeCall()');
		try {
			if (!calleeNumber) {
				xlog.info("[DEBUG] handleMakeCall() calleeNumber is empty");
				return false;
			}

			let nextShouldUseVideo = false;
			// --- check permission or request depends on audio / video usage
			/*
			let hasEnoughPermission = await this.checkAndRequestPhoneCallPermission(true, nextShouldUseVideo);
			if (!hasEnoughPermission) {
				xlog.info('handleMakeCall(): FAILED. user denied phone call permission');
				LIB.showAlertModal({
					title: I18n.t('Permission_PhoneCallDenied_title'),
					msg: I18n.t('Permission_PhoneCallDenied_msg') + '\n\n' + I18n.t('ManualGrantPermissionGuide'),
					buttons: [
						{
							text: I18n.t('GoToAppPermissionSettings'),
							onPress: () => {
								this.openAppSettings();
							},
						},
						{
							// --- Press Cancel, continue without bother
							ghost: true,
							text: I18n.t('GotIt'),
						},
					]
				});

				return false;
			}
			*/

			// --- FOR DEBUG, not using nginx as reverse proxy, but it needs to install TTC CA on your browser
			//serverIpPort = 'sip.yohalabs.com:443';
			//serverIpPort = 'sip1-dev.yohalabs.com:443';

			// --- default use nginx as reverse proxy, because we need Let's Encrypt for browser. sip-proxy.sayahoy.info:443 or sip-proxy-dev.sayahoy.info:443
			// --- change this at app_setup.js
			let serverIpPort = util.format('%s:%s', AppSetup.sipProxyDomain, AppSetup.sipProxyPort);

			if (AppSetup.isDebug && this.props.query.queryString.customSipServer) {
				// --- only allow 443 sip server port
				let customSipServer = this.props.query.queryString.customSipServer;
				if (!customSipServer.endsWith(':443')) {
					customSipServer += ':443';
				}

				// --- only allow our domain 
				if (customSipServer.endsWith('sayahoy.info:443') || customSipServer.endsWith('yohalabs.com:443')) {
					serverIpPort = customSipServer;
					xlog.info('[Cue][handleMakeCall] !!! use custom server: %s', customSipServer);
				}
			}
			let calleeUri = 'sip:' + calleeNumber + '@' + serverIpPort;

			// --- if we use push wake up, it means we don't have persistent connections.
			if (!this.ua) {
				this.createSipUaInstance({
					customServerIpPort: serverIpPort,
					moveTaskToBackAfterEnd: false,
					rel100: true,
				});
				if (!this.ua) {
					xlog.info("[Cue][handleMakeCall] this.ua is empty");
					return false;
				}
			}

			//if (this.ua && !this.ua.isConnected()) {
			if (this.ua) {
				this.ua.start();
			}
			//if ( 0 && this.localMediaStream === false) {
			//	this.sipGetMediaStream();
			//}
			//this.dispatch(Actions.UPDATE_CALL_SESSION_EVENT('trying'));
			this.setState({call_session_event: 'trying'});
			return this.sendCall(calleeUri, calleeName, calleeNumber, nextShouldUseVideo, extenCallGroup, callerApartmentInfo);
		} catch (e) {
			xlog.info('handleMakeCall(): FAILED. err = %s, stack = ', e, e.stack);
			return false;
		}
	}

	sendCall(calleeUri, calleeName, calleeNumber, shouldUseVideo, extenCallGroup, callerApartmentInfo) {
		if (!this.ua) {
			xlog.info("[Cue][sendCall] this.ua is empty");
			return false;
		}

		// --- the last args is sdp modifiers. An Array of Function returning Promise. Optional modifiers that will be applied to the incoming or outgoing description.
		this.session = this.ua.invite(calleeUri, this.getInviteAcceptOptions({enableVideo: shouldUseVideo, extenCallGroup, callerApartmentInfo}), [LibSDP.customSdpHacks]);
		this.setState({call_session_active: 1, call_session_callid: this.session.request.callId}, () => {
			this.setCallSessionEvents(this.session.request.callId);
			//InCallManager.start({media: (shouldUseVideo ? 'video' : 'audio'), ringback: '_DTMF_'});
			return true;
		});

	}

	getSessionDescriptionHandlerOptions({enableVideo = '', extenCallGroup = '', callerApartmentInfo = '', isUaInit = false} = {}) {
		// --- true or https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
		let constraints = {
			audio: {
				// --- A ConstrainDOMString object specifying a device ID or an array of device IDs which are acceptable and/or required.
				//deviceId

				// --- A ConstrainDOMString object specifying a group ID or an array of group IDs which are acceptable and/or required.
				//groupId

				// --- A ConstrainBoolean object which specifies whether automatic gain control is preferred and/or required.
				autoGainControl: true,

				// --- A ConstrainLong specifying the channel count or range of channel counts which are acceptable and/or required.
				//channelCount

				// --- A ConstrainBoolean object specifying whether or not echo cancellation is preferred and/or required.
				echoCancellation: true,

				// --- A ConstrainDouble specifying the latency or range of latencies which are acceptable and/or required.
				//latency

				// --- A ConstrainBoolean which specifies whether noise suppression is preferred and/or required.
				noiseSuppression: true,

				// --- A ConstrainLong specifying the sample rate or range of sample rates which are acceptable and/or required.
				//sampleRate

				// --- A ConstrainLong specifying the sample size or range of sample sizes which are acceptable and/or required.
				//sampleSize

				// --- A ConstrainDouble specifying the volume or range of volumes which are acceptable and/or required.
				volume: 0.8,
			},
			video: false,
			/*
			video: {
				// --- A ConstrainDouble specifying the video aspect ratio or range of aspect ratios which are acceptable and/or required.
				aspectRatio

				// --- A ConstrainDOMString object specifying a facing or an array of facings which are acceptable and/or required.
				facingMode

				// --- A ConstrainDouble specifying the frame rate or range of frame rates which are acceptable and/or required.
				frameRate

				// --- A ConstrainLong specifying the video height or range of heights which are acceptable and/or required.
				height

				// --- A ConstrainLong specifying the video width or range of widths which are acceptable and/or required.
				width

				// --- A ConstrainDOMString object specifying a mode or an array of modes the UA can use to derive the resolution of a video track. Allowed values are none and crop-and-scale. none means that the user agent uses the resolution provided by the camera, its driver or the OS. crop-and-scale means that the user agent can use cropping and downscaling on the camera output  in order to satisfy other constraints that affect the resolution.
				resizeMode
			}
			*/
		};

		// --- 500 ~ 5000, default 5000
		let iceCheckingTimeout = 5000;

		// --- This is a workaround for some unexpected behavior in Firefox 61+. Default value is false.
		let alwaysAcquireMediaFirst = false;

		// --- Array of Function returning Promise. A set of default modifiers to use every time a description is requested or set by the Session Description Handler. These modifiers will occur before modifiers passed by a specific call to the Session Description Handler.
		//modifiers: [];

		// --- Options to be passed to the WebRTC PeerConnection constructor.
		let rtcConfiguration = {
			// --- Specifies how to handle negotiation of candidates when the remote peer is not compatible with the SDP BUNDLE standard. This must be one of the values from the enum RTCBundlePolicy. If this value isn't included in the dictionary, "balanced" is assumed.
			bundlePolicy: 'balanced',
			// --- An Array of objects of type RTCCertificate which are used by the connection for authentication. If this property isn't specified, a set of certificates is generated automatically for each RTCPeerConnection instance.
			//certificates: [],
			// --- An unsigned 16-bit integer value which specifies the size of the prefetched ICE candidate pool. The default value is 0 (meaning no candidate prefetching will occur). You may find in some cases that connections can be established more quickly by allowing the ICE agent to start fetching ICE candidates before you start trying to connect, so that they're already available for inspection when RTCPeerConnection.setLocalDescription() is called. Changing the size of the ICE candidate pool may trigger the beginning of ICE gathering.
			//iceCandidatePoolSize: 0,
			iceServers: [
				{
					//urls: ['stun://stun2.yohalabs.com:443']
					urls: ['stun:stun2.yohalabs.com:443']
				},
			],
			// --- 'all' - default. 'relay' - Only ICE candidates whose IP addresses are being relayed, such as those being passed through a TURN server, will be considered.
			//iceTransportPolicy: 'all',
			// --- A DOMString which specifies the target peer identity for the RTCPeerConnection. If this value is set (it defaults to null), the RTCPeerConnection will not connect to a remote peer unless it can successfully authenticate with the given name.
			//peerIdentity: '',
			// --- The RTCP mux policy to use when gathering ICE candidates, in order to support non-multiplexed RTCP. The value must be one of those from the RTCRtcpMuxPolicy enum. The default is "require".
			rtcpMuxPolicy: "require", // --- "negotiate" is being deprecated
		};

		// --- Options to be passed to the WebRTC Peer Connection createOffer or createAnswer call.
		let RTCOfferOptions = {
			// --- To restart ICE on an active connection, set this to true. This will cause the returned offer to have different credentials than those already in place. If you then apply the returned offer, ICE will restart. Specify false to keep the same credentials and therefore not restart ICE. The default is false.
			iceRestart: false,
			// --- Some codecs and hardware are able to detect when audio begins and ends by watching for "silence" (or relatively low sound levels) to occur. This reduces network bandwidth used for audio by only sending audio data when there's actually something to broadcast. However, in some cases, this is unwanted. For example, in the case of music or other non-voice transmission, this can cause loss of important low-volume sounds. Also, emergency calls should never cut audio when quiet. This option defaults to true (voice activity detection enabled).
			voiceActivityDetection: true,
			offerToReceiveAudio: true,
			offerToReceiveVideo: (enableVideo ? true : false),
		};

		// --- combine options by type, it's really weird.
		let options = {};
		if (isUaInit) {
			options = {
				constraints,
				peerConnectionOptions: {
					rtcConfiguration,
					iceCheckingTimeout,
				},
				alwaysAcquireMediaFirst,
				RTCOfferOptions,
			};

		} else {
			options = {
				constraints,
				iceCheckingTimeout,
				alwaysAcquireMediaFirst,
				rtcConfiguration,
				RTCOfferOptions,
			};
		}

		return options;

		// --- sip.js only consumes when making a call
		/*
		RTCConstraints: {
			mandatory: {
			},
			optional: [
				{DtlsSrtpKeyAgreement: 'true'},
				{googDscp: 'true'},
				{googIPv6: 'false'},
			],
		},
		*/
	}

	getInviteAcceptOptions({enableVideo = '', extenCallGroup = '', callerApartmentInfo = ''} = {}) {
		let options = {
			extraHeaders: this.getSipAppendExtraHeaders({extenCallGroup, callerApartmentInfo}),
			//anonymous: false,	// ---indicating whether the call should be done anonymously. Default value is false
			//rel100: SIP.C.supported.UNSUPPORTED, // --- Optionally declare support or requirement of reliable provisional responses (100rel), as defined in RFC3262. Default is Unsupported.
			//inviteWithoutSdp: false, // --- 	If true, send the INVITE with no SDP offer. In this case, the SDP offer is to be generated by the remote endpoint, and the SDP answer will be sent in an ACK or PRACK. Default is false (send with SDP).
			sessionDescriptionHandlerOptions: this.getSessionDescriptionHandlerOptions({enableVideo, extenCallGroup, callerApartmentInfo, isUaInit: false}),
		}

		return options;
	}

	handleHangUp() {
		try {
			if (this.state.call_session_active !== false) {
				if (this.session.startTime) {
					if (!this.session.endTime) {
						this.session.bye();//{extraHeaders: this.etSipAppendExtraHeaders()}
						xlog.info('call hangup');
					} else {
						xlog.info('session already ended');
					}
				} else {
					if (this.state.call_session_active === 1) {
						this.session.cancel();//{extraHeaders: this.getSipAppendExtraHeaders()}
						xlog.info('call canceled');
					} else if (this.state.call_session_active === 2) {
						this.session.reject();//{extraHeaders: this.getSipAppendExtraHeaders()}
						xlog.info('call rejected');
					}
				}
			} else {
				xlog.info('no call session to terminate.');
			}
		} catch (err) {
			xlog.info("[Cue][handleHangUp] this.session.reject() Exception: %j", err);
			this.destroyCallSession();
		}
		/*
		// --- will do this in destroyCallSession()
		InCallManager.stop({'busytone': false});
		if (AppSetup.isPushWakeUpEnabled === true) {
			//setTimeout(this.closeSipUaInstance, this.sipCloseLingerMs);
			this.closeSipUaInstance();
		}
		this.dispatch(Actions.UPDATE_CALL_SESSION_ACTIVE(false));
		this.dispatch(Actions.UPDATE_CALL_SESSION_CALLID(''));
		*/
	}

	updateTalkingCallInfoPartial(callInfo) {
		this.setState({
			talkingCallInfo: {...this.state.talkingCallInfo, ...callInfo},
		});
	}

	updateTalkingCallInfo(talkingCallInfo) {
		this.setState({ talkingCallInfo });
	}

	destroyCallSession(setup) {
		if (typeof setup === 'undefined') setup = {};
		this.remoteAudio.srcObject = null;
		this.remoteAudio.pause();
		this.session = false;
		this.sdp = false;
		this.closeSipUaInstance();
		//this.logSessionTime();
		//this.updateTalkingCallData(null);

		// --- we only release local media stream. remote will release automatically.
		if (this.state.localMediaStream !== null) {
			// --- release tracks
			let tracks = this.state.localMediaStream.getTracks();
			if (tracks && tracks.length > 0) {
				tracks.forEach((element, index, array) => {
					try {
						this.state.localMediaStream.removeTrack(element);
					} catch (e) { /* just try remove it as clean as possible. do not fail. */ }
				});
			}
			// --- release local stream to prevent slowing on the next invoke
			try {
				this.state.localMediaStream.release();
			} catch (e) { /* just try remove it as clean as possible. do not fail. */ }
		}

		this.setState({
			call_session_active: false,
			call_session_callid: '',
			session_call_start_time: 0,
			call_session_event: 'terminated',

			localMediaStream: null,
			remoteMediaStream: null,
			talkingCallInfo: {},
		});

	}

	onPressCallButton(calleeNumber, calleeName) {
		//this.remoteAudio.play() // --- no need to play here, we've already has user interaction

		// --- start etect availability on client
		this.detectRTCProcess()
		.then(this.onDetectRTCLoad)
		.catch((err) => {
			xlog.info('[App] onPressCallButton().DetectRTCProcess() failed. err = ', err);
		})
		.then(() => {
			let { available, unsupportedMsg } = this.detectRTCCheckAvailability();
			xlog.info('[App] onPressCallButton().detectRTCCheckAvailability() available = %s, unsupportedMsg = %s', available, unsupportedMsg);

			if (available) {
				try {
					this.handleMakeCall({calleeName, calleeNumber, preferVideo: false });
				} catch (err) {
					xlog.info('[App] onPressCallButton().handleMakeCall() failed. err = ', err);
				}
			}
		})
	}

	onPressCallEndButton() {
		this.destroyCallSession();
	}

	onAfterOpenModal() {
	}

	onCloseModal() {
		this.setState({
			modal: {
				isModalOpen: false,
			}
		});
	}

	render() {
		//xlog.info('[App] render() this.state = ', this.state);

		if (!this.state.appInitialized) {
			return (
				<Layout>
					<View>
						<Text>Loading...</Text>
					</View>
				</Layout>
			)

		} else if (!this.state.webcallInfo.calleeNumber) {
			return (
				<Layout>
					<View>
						<Text>{I18n.t('IncorrectWebCallData')}</Text>
					</View>
				</Layout>
			)
		} else {
			// --- will display message when: 1. debug, 2. really unavailable, see: detectRTCCheckAvailability
			let { available, unsupportedMsg } = this.detectRTCCheckAvailability();

			let { calleeNumber, calleeName } = this.state.webcallInfo;

			let expectedHeight, expectedWidth;
			if (this.props.windowHeight >= this.props.windowWidth) {
				// --- portrait
				expectedHeight = this.props.windowHeight * 0.95;
				expectedWidth = this.props.windowWidth * 0.95;
			} else {
				// --- landscape, use width as height to display as portrait
				expectedHeight = this.props.windowHeight * 0.95;
				expectedWidth = expectedHeight * 0.5625;
			}
			expectedWidth = parseInt(expectedWidth);
			expectedHeight = parseInt(expectedHeight);

			//icon=<MaterialIcon name="call" size={20}/>
			/*
				  <p>
					Screen height is: {this.props.windowHeight}
					<br />
					Screen width is: {this.props.windowWidth}
					<br />
					Expected height is: {expectedHeight}
					<br />
					Expected width is: {expectedWidth}
				  </p>
			*/
			return (
				<Layout style={{ alignItems: 'center', justifyContent: 'center' }}>
					<View style={{height: expectedHeight, width: expectedWidth, maxHeight: this.props.windowHeight, maxWidth: this.props.windowWidth}}>
						{ this.state.call_session_active === false &&
							<View style={styles.readyToCallContainer}>
								<Text style={{fontWeight: 'bold', fontSize: 20}}>
									{calleeName}
								</Text>
								<ButtonElement
									buttonStyle={ {height: 80, width: 80, borderRadius: 40} }
									//style={ iconStyle }
									color={Theme.MainColor}
									onPress={() => { this.onPressCallButton(calleeNumber, calleeName); }}
									icon={{type: 'material', name: 'call', size: 40, color: 'white'}}

									disabled={false}
								/>
								<View style={styles.center}>
									<Text style={[styles.reminderText, styles.center]}>
										{'提醒您︰此為免費電話\n系統將以免持聽筒通話，建議使用耳麥通話。'}
									</Text>
									<Text style={[styles.warningText, styles.center]}>
										{I18n.t('WebWarning')}
									</Text>
								</View>
								{ !!unsupportedMsg && (AppSetup.isDebug || !available) &&
									<View style={styles.center}>
										<Text style={styles.warningText}>
											{unsupportedMsg}
										</Text>
									</View>
								}
							</View>
						}
						{ this.state.call_session_active !== false &&
							<Talk parent={this} expectedWidth={expectedWidth} expectedHeight={expectedHeight} />
						}
					</View>
					<View>
						<audio id="remoteAudio" volume="1.0" ref={(audio) => { this.remoteAudio = audio }}></audio>
					</View>
					{ this.state.modal.isModalOpen && (
						<Modal
							isOpen={this.state.modal.isModalOpen}
							onAfterOpen={this.onAfterOpenModal}
							onRequestClose={this.onCloseModal}
							contentLabel="Example Modal"
							style={{
								overlay: {
									position: "fixed",
									top: 0,
									left: 0, 
									right: 0,
									bottom: 0,
									backgroundColor: "rgba(255, 255, 255, 0.75)"
								},
								content: {
									position: "absolute",
									top: expectedHeight * 0.33,
									left: (this.props.windowWidth - expectedWidth) / 2,
									right: "auto",
									bottom: "auto", 
									width: expectedWidth,
									//height: expectedHeight / 2,

									border: "1px solid #ccc",
									background: "#fff",
									overflow: "auto",
									WebkitOverflowScrolling: "touch",
									borderRadius: "4px",
									outline: "none",
									padding: "20px"
								}
							}}		  
						>
							<View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
								{this.state.modal.view || null}
								<ButtonElement
									buttonStyle={[styles.mainButtonContainer, {width: expectedWidth * 0.9}]}
									title={'cancel'}
									titleStyle={{color: Theme.MainColor, fontSize: 14}}
									onPress={this.onCloseModal}
									icon={{type: 'material', name: 'close', size: 30, color: Theme.MainColor}}
								/>
							</View>
						</Modal>
					)}
				</Layout>
			);
		}
	}
}

const styles = StyleSheet.create({
	reminderText: {
		color: 'grey',
		alignSelf: 'center',
		fontSize: 16,
	},
	warningText: {
		color: 'red',
		alignSelf: 'center',
		fontSize: 16,
	},
	readyToCallContainer: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'space-around',
		borderWidth: 1,
		padding: 10,
		borderColor: '#DDD',
		borderStyle: 'solid',
	},
	mainButtonContainer: {
		borderRadius: 30,
		borderWidth: 0,
		backgroundColor: 'white',
		//flex: 1,
		//width: AppSetup.dimensions.width * .95,
		alignSelf: 'stretch',
		alignItems: 'center',
		justifyContent: 'center',
	},
	center: {
		alignItems: 'center',
		justifyContent: 'center',
	},
});

// windowSize will pass height and width to wrapped components
// --- Screen width is: {this.props.windowWidth}
// --- Screen height is: {this.props.windowHeight}
App = WindowSize(App);

let hotWrapper = () => () => App;
if (Platform.OS === 'web') {
	const { hot } = require('react-hot-loader');
	hotWrapper = hot;
}
export default hotWrapper(module)(App);
