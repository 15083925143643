import os from 'os';
const __DEV__ = (process.env.NODE_ENV === 'development'); // --- this node build env is dev or not

const config = {
	libTTCPath: os.platform() === 'darwin' ? '/Users/libTTC' : '/home/libTTC',
	host: '127.0.0.1',
	port: (parseInt('9528', 10) || 9528),
	logToConsole: __DEV__ && !('false' === 'false'), // --- should log to console or not
	isDebug: __DEV__ && !('false' === 'false'), // --- this server is our dev server or not
	landingServerDomain: 'sayahoy.info', // --- landing server proxies api call to api server
	landingServerClientKey: 'aks3E2ddr4SKOPIJfeh28hsHUJOEHCB293', // --- this is required for landing server which simply checks key to prevent randomly, automatically sniffing attacks
	//sipProxyDomain: 'sip-proxy-dev.sayahoy.info', // --- Nginx Let's Encrypt Reverse Proxy to DEV
	//sipProxyDomain: 'sip-proxy.sayahoy.info', // --- Nginx Let's Encrypt Reverse Proxy to PRODUCTION
	//sipProxyDomain: 'sip1-dev.yohalabs.com', // --- self-signed cert DEV
	//sipProxyDomain: 'sip.yohalabs.com', // --- self-signed cert PRODUCTION
	sipProxyDomain: 'sip-proxy.sayahoy.info', // --- default use nginx to handle as reverse proxy, web sip client will send invite to it
	sipProxyPort: '443',
	//sipCallerUsername: '5981034',
	//sipCallerPassword: '20262936',
	Theme: 'light',
};

export default config;
