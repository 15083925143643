import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import queryString from 'query-string';

// ===== START for react-native-vector-icon =====
// --- https://github.com/oblador/react-native-vector-icons#web-with-webpack
// --- should import ttf and generate css for your desired font

// Use prebuilt version of RNVI in dist folder
//import Icon from 'react-native-vector-icons/dist/FontAwesome';

// Generate required css
import iconFontFontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import iconFontMaterial from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
let iconFontStyles = '';
iconFontStyles += `@font-face {
	src: url(${iconFontFontAwesome});
	font-family: FontAwesome;
}`;
iconFontStyles += `@font-face {
	src: url(${iconFontMaterial});
	font-family: MaterialIcons;
}`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
	style.styleSheet.cssText = iconFontStyles;
} else {
	style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);
// ===== END for react-native-vector-icon =====

/*
 * supported query string:
 *   customCallerUsername
 *   customCallerPassword
 *   customSipServer
 */
let query = {
	//calleeNumber: '',
	//calleeName: '',
	//caller: '',
	//callerPwd: '',
	callHash: window.location.pathname.split("/").slice(-1)[0] || '',
	queryString: queryString.parse(window.location.search) || {}, // --- for full path: `window.location.href`
};

//console.log('window.location.search = ', window.location.search);
//console.log('window.location.href = ', window.location.href);
//console.log('window.location.pathname = ', window.location.pathname);

ReactDOM.render(<App query={query} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
